/* eslint-disable eqeqeq */
import { IStoreState, ITimeReportEntry } from '@/interfaces'
import { IClockingInModel, IOrderModel, IUserModel, IWorkingSiteModel } from '@esse-group/shared'
import { Store } from 'vuex'
import moment from 'moment'
import isSameDay from 'date-fns/isSameDay'
import { findLastIndex } from './find-last-index'
import { WeekDay } from '@/enums/week-day'

export const userTimeReportComputer = async (params: {
  store: Store<IStoreState>,
  userIds?: string[]
  workingSiteIds?: string[],
  startDate: Date,
  endDate: Date
}): Promise<ITimeReportEntry[]> => {
  console.debug('userTimeReportComputer', { params })
  // 0. We prepare the variables
  const startPeriod = moment(params.startDate).startOf('day')
  const endPeriod = moment(params.endDate).endOf('day')

  // 1. We collect the user clocking ins
  let clockingIns: IClockingInModel[] = await params.store.dispatch(
    'clockingIns/getClockingInsFiltered',
    {
      userIds: params.userIds,
      workingSiteIds: params.workingSiteIds,
      from: startPeriod.toDate(),
      to: endPeriod.toDate()
    }
  )
  clockingIns = clockingIns.sort((a, b) => (a.createdTs - b.createdTs) + (a.userId.localeCompare(b.userId) - 0.5))

  // 2. Get the working sites and orders
  const workingSites: IWorkingSiteModel[] = params.store.state.workReports.workingSites
  const orders: IOrderModel[] = params.store.state.workReports.orders

  // 3. Get the working sites
  const users: IUserModel[] = params.store.state.workReports.users

  console.debug('userTimeReportComputer', { clockingIns })

  // 4. We get the inquired user document
  const reports: ITimeReportEntry[] = clockingIns.reduce(
    (acc, clockIn): ITimeReportEntry[] => {
      const clkInMoment = moment.unix(clockIn.createdTs)
      const clkInDate = clkInMoment.toDate()
      const idx = findLastIndex(acc, accClkIn => isSameDay(accClkIn.day, clkInDate) && accClkIn.userId === clockIn.userId)

      // Get the related Working Site
      const ws = clockIn.workingSiteId ? workingSites.find(ws => ws.id == clockIn.workingSiteId) : undefined
      const wsClockInTiming = ws && ws.timings ? ws.timings.find(timing => (timing.weekDay == WeekDay.SUNDAY ? 0 : timing.weekDay) === clkInMoment.day()) : undefined

      // Get the related Order
      const o = clockIn.orderId ? orders.find(o => o.id == clockIn.orderId) : undefined

      // Get the related user
      const u = clockIn.userId ? users.find(u => u.id == clockIn.userId) : undefined

      const createdByUser = clockIn.createdByUserId || clockIn.userId
      const newEntry: ITimeReportEntry = {
        agency: u ? u.agency : undefined,
        day: clkInDate,
        in: clockIn.isClockingOut ? undefined : clkInDate,
        inUserId: clockIn.isClockingOut ? undefined : createdByUser,
        userId: clockIn.userId || undefined,
        userName: u ? u.name || undefined : undefined,
        userSurname: u ? u.surname || undefined : undefined,
        out: clockIn.isClockingOut ? clkInDate : undefined,
        userBreakTimeMinutes: u ? u.breakTimeMinutes || undefined : undefined,
        outUserId: clockIn.isClockingOut ? createdByUser : undefined,
        diffMinutesRounded: 0,
        overtimeRoundedMinutes: 0,
        orderId: clockIn.orderId || undefined,
        orderName: o ? o.name : undefined,
        workingSiteName: ws ? ws.name : undefined,
        workingHoursRounded: 0,
        workingSiteId: clockIn.workingSiteId != undefined ? clockIn.workingSiteId : undefined
      }
      console.log('newEntry', { newEntry, o, ws, 'clkInMoment.day()': clkInMoment.day() })

      // Round the current in and out values
      if (newEntry.out instanceof Date) {
        const outDate = moment(newEntry.out)

        if (wsClockInTiming) {
          newEntry.outRounded = outDate.clone().floor(30, 'minutes').toDate()
          newEntry.outImprecision = false
        } else {
          newEntry.outRounded = undefined
          newEntry.outImprecision = true
        }
      } else {
        newEntry.outRounded = newEntry.out
      }

      if (newEntry.in instanceof Date) {
        const inDate = moment(newEntry.in)

        if (wsClockInTiming) {
          newEntry.inRounded = inDate.clone().ceil(30, 'minutes').toDate()
          newEntry.inImprecision = false
        } else {
          newEntry.inRounded = undefined
          newEntry.inImprecision = true
        }
      } else {
        newEntry.inRounded = newEntry.in
      }

      if (idx === -1) {
        console.log('newEntry - is new')
        acc.push(newEntry)
      } else {
        console.log('newEntry - complete the previuous one')
        if (clockIn.isClockingOut) {
          // Clocking out case
          if (acc[idx].out != undefined) {
            // Another clocking out for same day
            acc.push(newEntry)
          } else {
            // Add clocking out to the same day
            acc[idx].outUserId = createdByUser
            acc[idx].out = newEntry.out
            acc[idx].outRounded = newEntry.outRounded
            acc[idx].outImprecision = newEntry.outImprecision
            acc[idx].outAdjusted = newEntry.outAdjusted
          }
        } else {
          // Clocking in case
          if (acc[idx].in != undefined) {
            // Another clocking in for same day
            acc.push(newEntry)
          } else {
            // Add clocking in to the same day
            acc[idx].inUserId = createdByUser
            acc[idx].in = newEntry.in
            acc[idx].inRounded = newEntry.inRounded
            acc[idx].inImprecision = newEntry.inImprecision
            acc[idx].inAdjusted = newEntry.inAdjusted
          }
        }
      }

      return acc
    },
    [] as ITimeReportEntry[]
  )

  // 3. We compute the timings
  for (const entry of reports) {
    // 3.2 Diff hours
    if (entry.inRounded != undefined && entry.outRounded != undefined) {
      const duration = moment.duration(moment(entry.outRounded).diff(moment(entry.inRounded)))
      entry.diffMinutesRounded = (duration.asHours()) * 60

      const user = users.find(u => u.id == entry.userId)
      if (
        user
        && user.breakTimeMinutes
        && entry.day.getDay() != 0 // Sunday
        && entry.day.getDay() != 6 // Saturday
      ) {
        entry.diffMinutesRounded -= user.breakTimeMinutes
      }

      // Get the related Working Site
      const ws = entry.workingSiteId ? workingSites.find(ws => ws.id == entry.workingSiteId) : undefined
      const wsClockInTiming = ws && ws.timings ? ws.timings.find(timing => (timing.weekDay == WeekDay.SUNDAY ? 0 : timing.weekDay) === moment(entry.day).day()) : undefined

      if (wsClockInTiming == undefined) { continue }
      const wsDurationDifference = ((wsClockInTiming.closingTimeHours * 60) + wsClockInTiming.closingTimeMinutes) - ((wsClockInTiming.openingTimeHours * 60) + wsClockInTiming.openingTimeMinutes)
        - entry.diffMinutesRounded

      const wsInDateTime = moment(entry.day).set('hour', wsClockInTiming.openingTimeHours).set('minute', wsClockInTiming.openingTimeMinutes)
      entry.inAdjusted = wsInDateTime.toDate()
      if (wsDurationDifference > 0) {
        entry.outAdjusted = wsInDateTime.clone().add('minutes', entry.diffMinutesRounded).toDate()
      } else {
        entry.outAdjusted = moment(entry.day).set('hour', wsClockInTiming.closingTimeHours).set('minute', wsClockInTiming.closingTimeMinutes).toDate()
      }

      if (user && user.workingTime) {
        const userWorkingTime = user.workingTime.find(uwt => uwt.weekDay + 1 == moment(entry.day).day())

        if (userWorkingTime) {
          entry.overtimeRoundedMinutes = userWorkingTime.minutes < entry.diffMinutesRounded ? entry.diffMinutesRounded - userWorkingTime.minutes : 0
          entry.workingHoursRounded = entry.overtimeRoundedMinutes > 0 ? userWorkingTime.minutes : entry.diffMinutesRounded
        } else {
          entry.overtimeRoundedMinutes = 0
          entry.workingHoursRounded = entry.diffMinutesRounded
        }
      } else {
        entry.overtimeRoundedMinutes = 0
        entry.workingHoursRounded = entry.diffMinutesRounded
      }
    }
  }

  console.debug('userTimeReportComputer', { reports })

  return reports
}
