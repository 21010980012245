
import { defineComponent } from 'vue'
import ModalDialog from '@/components/dialogs/ModalDialog.vue'
import PrimaryButton from '@/components/buttons/PrimaryButton.vue'
import Loader from '@/components/Loader.vue'
import UploadForm from '@/components/UploadForm.vue'

export default defineComponent({
  name: 'UploadDocumentModalDialog',

  emits: ['close'],

  components: {
    ModalDialog, PrimaryButton, Loader, UploadForm
  }
})
