
import { defineComponent } from 'vue'
import ModalDialog from './ModalDialog.vue'
import PrimaryButton from '../buttons/PrimaryButton.vue'
import Loader from '../Loader.vue'
import { DeleteIcon } from '@/components/icons'
import { AlertType } from '@/enums'

export default defineComponent({
  name: 'DeleteWorkingSite',

  emits: ['close'],

  components: {
    ModalDialog, PrimaryButton, Loader, DeleteIcon
  },

  props: {
    workingSiteId: { type: String, required: true }
  },

  data: function () {
    return {
      loading: false
    }
  },

  computed: {
    orderId: function () {
      return this.$route.params.orderId
    }
  },

  methods: {
    onDelete: async function () {
      this.loading = true

      try {
        const result = await this.$store.dispatch(
          'orders/deleteWorking',
          { workingSiteId: this.workingSiteId, orderId: this.orderId }
        )

        this.loading = false
        if (!result) {
          await this.$store.dispatch('alerts/create', {
            message: 'eliminazione blocco fallita',
            type: AlertType.error
          })
          return
        }

        await this.$store.dispatch('alerts/create', {
          message: 'eliminazione blocco riuscita',
          type: AlertType.success
        })

        await this.$store.dispatch('orders/getWorkingSites', this.orderId)

        this.$emit('close')
      } catch (error) {
        console.error('error deleting the working site', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', {
          message: 'eliminazione blocco fallita',
          type: AlertType.error
        })
      }
    }
  }
})
