import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60bfdb9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "working-site-document-row mb-2 grid grid-cols-4" }
const _hoisted_2 = { class: "document col-span-2 flex items-center truncate" }
const _hoisted_3 = { class: "buttons truncate" }
const _hoisted_4 = { class: "font-bold text-left name text-left truncate w-max-24" }
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { class: "delete flex items-center justify-end" }
const _hoisted_7 = {
  key: 0,
  class: "edit flex items-center justify-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlueDocumentIcon = _resolveComponent("BlueDocumentIcon")!
  const _component_DeleteDocumentDialog = _resolveComponent("DeleteDocumentDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BlueDocumentIcon, {
        onClick: _ctx.openDocument,
        mimeTypeSlag: _ctx.mimeTypeSlag
      }, null, 8, ["onClick", "mimeTypeSlag"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.modelValue.title), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", {
            class: "text-sm font-bold text-primary-600 pr-4 cursor-pointer",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openDocument && _ctx.openDocument(...args)))
          }, "Vedi originale")
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.isDocumentToBeApproved)
        ? (_openBlock(), _createElementBlock("h4", {
            key: 0,
            class: "block float-right font-bold cursor-pointer bg-tertiary-100 text-tertiary-900 approve-document",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onApproveDocument && _ctx.onApproveDocument(...args)))
          }, "Approva documento"))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.isReadOnly)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("h4", {
            class: "block float-right delete-button font-bold cursor-pointer",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isShowingDeleteDialog = true))
          }, "Elimina")
        ]))
      : _createCommentVNode("", true),
    (_ctx.isShowingDeleteDialog)
      ? (_openBlock(), _createBlock(_component_DeleteDocumentDialog, {
          key: 1,
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isShowingDeleteDialog = false)),
          documentId: _ctx.modelValue.id,
          "working-site-id": _ctx.workingSiteId,
          "communication-id": _ctx.communicationId,
          scope: _ctx.scope,
          "user-id": _ctx.userId,
          title: _ctx.modelValue.title
        }, null, 8, ["documentId", "working-site-id", "communication-id", "scope", "user-id", "title"]))
      : _createCommentVNode("", true)
  ]))
}