
import { ClockingInScope } from '@/enums'
import { IAlgoliaClockingInModel, IWorkingSiteModel } from '@esse-group/shared'
import moment from 'moment'
import { defineComponent } from 'vue'
import { humanizedClockingInTiming } from '@/utilities'

export default defineComponent({
  name: 'ClockingInCard',

  created: function () {
    (this as any).moment = moment
  },

  data () {
    return {
      workingSiteName: '',
      ClockingInScope,
      humanizedClockingInTiming
    }
  },

  props: {
    modelValue: { type: Object as () => IAlgoliaClockingInModel, required: true },
    scope: { type: Number as () => ClockingInScope, required: true }
  },

  async mounted () {
    if (
      (this as any).scope === ClockingInScope.user
      && (this as any).modelValue !== undefined
      && (this as any).modelValue.workingSiteId !== undefined
    ) {
      const fetchedWs = (this.$store.state.orders.workingSites as IWorkingSiteModel[]).find(w => w.id === (this as any).modelValue.workingSiteId)

      if (fetchedWs !== undefined) {
        this.workingSiteName = fetchedWs.name
      } else {
        try {
          const result = await this.$store.dispatch(
            'orders/getWorkingSite', this.modelValue.workingSiteId
          ) as IWorkingSiteModel
          console.log('getWorkingSiteName - result', { result })

          if (result !== undefined) {
            this.workingSiteName = result.name
          }
        } catch (error) {
          console.error('ClockingInCard - getWorkingSiteName - error getting the working site name', { error })
          console.error(error)
        }
      }
    }
  }
})
