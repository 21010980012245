
import { defineComponent } from 'vue'
import { BlueDocumentIcon } from '@/components/icons'
import { DeleteDocumentDialog } from '@/components/dialogs'
import { IDocumentModel } from '@esse-group/shared'
import { getFileMimeTypeSlug } from '@/utilities'
import { createNamespacedHelpers } from 'vuex'
import { AlertType, DocumentScope } from '@/enums'
const { mapActions } = createNamespacedHelpers('documents')

export default defineComponent({
  name: 'DocumentCard',

  components: {
    BlueDocumentIcon, DeleteDocumentDialog
  },

  emits: ['update:modelValue'],

  props: {
    scope: { type: Number as () => DocumentScope, required: true, default: DocumentScope.workingSiteDocument },
    modelValue: { type: Object as () => IDocumentModel, required: true },
    isDocumentToBeApproved: { type: Boolean, default: false, required: false },
    isReadOnly: { type: Boolean, default: false, required: false },

    workingSiteId: { type: String, required: false },
    communicationId: { type: String, required: false },
    userId: { type: String, required: false }
  },

  data () {
    return {
      isShowingDeleteDialog: false,
      loading: false
    }
  },

  methods: {
    ...mapActions(['updateDocument']),
    openDocument: function () {
      window.open(this.modelValue.documentUrl, '_blank')
    },
    onApproveDocument: async function () {
      console.log('onApproveDocument')
      this.loading = true

      try {
        const result = await this.updateDocument({
          workingSiteId: this.workingSiteId,
          scope: DocumentScope.workingSiteDocument,
          document: {
            id: this.modelValue.id,
            approved: true
          }
        })
        this.loading = false
        if (!result) {
          await this.$store.dispatch('alerts/create', { message: 'approvazione documento fallita', type: AlertType.error })
          return
        }

        await this.$store.dispatch('alerts/create', { message: 'approvazione documento riuscita', type: AlertType.success })
      } catch (error) {
        console.error('error creating the document', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', { message: 'approvazione documento fallita', type: AlertType.error })
      }
    }
  },

  computed: {
    mimeTypeSlag: function (): string { return getFileMimeTypeSlug((this.modelValue as any).mimeType) }
  }
})
