import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-967324da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "alert-top ml-4" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["p-2 px-3 mx-6 my-3 rounded text-base flex items-center text-center", `bg-white`])
    }, [
      _createElementVNode("span", {
        class: "text-primary-800 font-bold",
        innerHTML: _ctx.data.message
      }, null, 8, _hoisted_2)
    ])
  ]))
}