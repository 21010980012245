import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00393670"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "document bg-primary-10 p-12 rounded m-auto cursor-pointer border-solid border-2 hover:border-primary-100" }
const _hoisted_2 = {
  key: 0,
  class: "icon m-auto"
}
const _hoisted_3 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlueDocumentIcon = _resolveComponent("BlueDocumentIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.modelValue)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_BlueDocumentIcon, {
            class: "m-auto",
            mimeTypeSlag: _ctx.mimeTypeSlag
          }, null, 8, ["mimeTypeSlag"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.modelValue.title), 1),
    _createElementVNode("button", {
      class: "view-more-button text-primary-400 pt-1 pr-4 pl-4 pb-1 mt-4 border-primary-400 text-center border-solid border-2 rounded",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openAttachment && _ctx.openAttachment(...args)))
    }, "Apri Documento")
  ]))
}