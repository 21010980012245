import { IAlgoliaUserModel, IClockingInModel, IUserModel } from '@esse-group/shared'
import { createNewUserCallable, usersClockingInCollection, usersCollection, usersIndex } from '@/utilities'
import { ActionContext, Module } from 'vuex'
import { firestoreAction, vuexfireMutations } from '@xquick-code/vuexfire'
import { IUsersStoreState } from '../interfaces'
import { updateUserCallable } from '@/utilities/firebase-utility'

const hitsPerPage = 300

export const UsersStore: Module<IUsersStoreState, unknown> = {
  namespaced: true,

  // setup the reactive todos property
  state: {
    user: undefined as IUserModel | undefined,
    users: [] as IUserModel[],
    usersSearchQuery: '',
    usersPage: 0
  },

  mutations: {
    ...vuexfireMutations,

    appendUser (state, user: IUserModel) {
      if (user === undefined || user.id === undefined) { return }
      if (state.users.findIndex(u => u.id === user.id) === -1) {
        state.users = [...state.users, user]
      }
    },
    setUsers (state, { users, page }) {
      console.log('UsersStore - setUsers', { users, page })
      state.users = users
      state.usersPage = page
    },
    setUsersSearchQuery (state, usersSearchQuery) {
      state.usersSearchQuery = usersSearchQuery
    },
    addUser (state, user) {
      state.users.push(user)
    },
    resetUser (state, users) {
      state.users = users
      state.usersSearchQuery = ''
      state.usersPage = 0
    }
  },

  actions: {
    async reloadUsers (
      context: ActionContext<IUsersStoreState, unknown>,
      params: {
        facetFilters?: string | readonly string[] | readonly (readonly string[])[] | undefined,
        filters?: string | undefined
      }
    ): Promise<boolean> {
      console.log('UsersStore - reloadUsers')
      try {
        const result = await usersIndex.search(
          '',
          {
            hitsPerPage,
            // eslint-disable-next-line eqeqeq
            facetFilters: params != undefined && params.facetFilters != undefined
              ? (
                  typeof params.facetFilters === 'string'
                    ? `${params.facetFilters} AND isArchived:-true`
                    : [...params.facetFilters, 'isArchived:-true'] as any
                )
              : 'isArchived:-true',
            filters: params ? params.filters : undefined
          }
        )
        context.commit('resetUser', result.hits)
      } catch (error) {
        console.error('UsersStore - error searching inside the users index', { context, error })
        return false
      }

      return true
    },

    async paginateUsers (
      context: ActionContext<IUsersStoreState, unknown>,
      params: {
        forceReload?: boolean,
        query: string,
        facetFilters?: string | readonly string[] | readonly (readonly string[])[] | undefined,
        filters?: string | undefined
      }
    ): Promise<void> {
      console.log('UsersStore - paginateUsers', params)
      context.commit('setUsersSearchQuery', params.query)

      const page = params.query === context.state.usersSearchQuery && !params.forceReload
        ? context.state.usersPage + 1
        : 0

      try {
        const result = await usersIndex.search(
          params.query,
          {
            // hitsPerPage,
            page,
            cacheable: false,
            // eslint-disable-next-line eqeqeq
            facetFilters: params != undefined && params.facetFilters != undefined
              ? (
                  typeof params.facetFilters === 'string'
                    ? `${params.facetFilters} AND isArchived:-true`
                    : [...params.facetFilters, 'isArchived:-true'] as any
                )
              : 'isArchived:-true',
            filters: params ? params.filters : undefined
          }
        )
        console.log('UsersStore - paginateUsers - result', result)
        context.commit(
          'setUsers',
          {
            page,
            users: params.forceReload === true ? result.hits : [...context.state.users, ...result.hits]
          }
        )
      } catch (error) {
        console.error('UsersStore - error searching inside the users index', { context, params, error })
      }
    },

    async instantGetUsers (
      context: ActionContext<IUsersStoreState, unknown>,
      ids: string[]
    ): Promise<IAlgoliaUserModel[]> {
      console.log('UsersStore - instantGetUsers', { ids })

      try {
        const result = await usersIndex.getObjects(ids, { cacheable: false })
        console.log('UsersStore - instantGetUsers - result', result)

        return result.results as IAlgoliaUserModel[]
      } catch (error) {
        console.error('UsersStore - instantGetUsers - error getting the users from the index', { context, ids, error })
        console.error(error)

        return []
      }
    },

    async instantSearchUsers (
      context: ActionContext<IUsersStoreState, unknown>,
      params: {
        query: string,
        facetFilters?: string | readonly string[] | undefined,
        hitsPerPage?: number | undefined,
        filters?: string | undefined,
        page?: number | undefined
      }
    ): Promise<IAlgoliaUserModel[]> {
      console.log('UsersStore - instantSearchUsers', params)

      try {
        const result = await usersIndex.search(
          params.query,
          {
            hitsPerPage: params.hitsPerPage,
            cacheable: false,
            // eslint-disable-next-line eqeqeq
            facetFilters: params != undefined && params.facetFilters != undefined
              ? (
                  typeof params.facetFilters === 'string'
                    ? [params.facetFilters, 'isArchived:-true']
                    : [...params.facetFilters, 'isArchived:-true'] as any
                )
              : 'isArchived:-true',
            filters: params ? params.filters : undefined,
            page: params.page
          }
        )
        console.log('UsersStore - instantSearchUsers - result', result)

        return result.hits as IAlgoliaUserModel[]
      } catch (error) {
        console.error('UsersStore - instantSearchUsers - error searching inside the users index', { context, params, error })

        return []
      }
    },

    // User
    getUser: firestoreAction(async (context, id: string): Promise<IUserModel | undefined> => {
      console.debug('getUser', { id })

      try {
        const result = await usersCollection.doc(id).get()
        console.log('UsersStore - instantWorkingSites - result', result)

        if (!result.exists) { return undefined }

        const user = result.data() as IUserModel
        context.commit('appendUser', user)

        return user
      } catch (error) {
        console.error('UsersStore - getUser - error getting a working site', { id, error })
        console.error(error)

        return undefined
      }
    }),
    bindUserRef: firestoreAction((context, payload) => {
      console.debug(payload)
      console.debug('bindUserRef', { payload })
      // context contains all original properties like commit, state, etc
      // and adds `bindFirestoreRef` and `unbindFirestoreRef`
      // we return the promise returned by `bindFirestoreRef` that will
      // resolve once data is ready
      return context.bindFirestoreRef('user', usersCollection.doc(payload.id))
    }),
    unbindUserRef: firestoreAction(({ unbindFirestoreRef }) => {
      console.debug('unbindUserRef')
      unbindFirestoreRef('user')
    }),

    updateTimings: firestoreAction(async (context, payload: {
      workingTime: { weekDay: number, minutes: number }[],
      userId: string,
      breakTimeMinutes: number
    }): Promise<boolean> => {
      console.debug('UsersStore - updateTimings', { payload })
      if (
        payload === undefined
        || payload.workingTime === undefined
      ) { return false }
      try {
        await usersCollection
          .doc(payload.userId)
          .update({
            workingTime: payload.workingTime,
            breakTimeMinutes: payload.breakTimeMinutes
          })
      } catch (error) {
        console.error('UsersStore - updateTimings - error adding a user timing', { context, payload, error })
        return false
      }

      console.debug('UsersStore - updateTimings - success')

      return true
    }),

    async createNewWorker (
      context: ActionContext<IUsersStoreState, unknown>,
      params: Partial<IUserModel> & { password: string }
    ): Promise<boolean> {
      console.debug('UsersStore - createNewWorker', { context, params })

      params.isEnabled = true
      params.workingTime = [
        { weekDay: 0, minutes: 480 },
        { weekDay: 1, minutes: 480 },
        { weekDay: 2, minutes: 480 },
        { weekDay: 3, minutes: 480 },
        { weekDay: 4, minutes: 480 },
        { weekDay: 5, minutes: 480 },
        { weekDay: 6, minutes: 480 }
      ]
      try {
        // unsubscribe can be called to stop listening for changes
        const result = await createNewUserCallable(params)
        return result.data.code
      } catch (error) {
        console.log('error creating the user', error)
        return false
      }
    },

    async createClockingIn (
      context: ActionContext<IUsersStoreState, unknown>,
      params: IClockingInModel
    ): Promise<boolean> {
      console.debug('UsersStore - createClockingIn', { context, params })

      if (params.userId === undefined) { return false }

      try {
        await usersClockingInCollection(params.userId)
          .doc(params.id)
          .set(JSON.parse(JSON.stringify(params)))
        console.log('UsersStore - createClockingIn - succeded')

        return true
      } catch (error) {
        console.error('UsersStore - createClockingIn - error creating the clocking in', { params, error })
      }

      return false
    },

    async updateUser (
      context: ActionContext<IUsersStoreState, unknown>,
      params: Partial<IUserModel>
    ): Promise<boolean> {
      console.debug('UsersStore - updateUser', { context, params })

      // unsubscribe can be called to stop listening for changes
      const result = await updateUserCallable(params)

      return result.data.code
    }
  }
}
