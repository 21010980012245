import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/auth'
import 'firebase/storage'
import config from '@/../google-services.json'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import store from '@/store'
import { IClockingInModel, IDocumentModel, IWorkingSiteModel, IWorkReport } from '@esse-group/shared'

// Get the firebase instance
export const app = firebase.initializeApp(config)

// Get a Firestore instance
export const db = app.firestore()
db.settings({ experimentalForceLongPolling: true })

// Get a Auth instance
export const auth = app.auth()

// Get a Auth instance
export const storage = app.storage()

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { Timestamp, GeoPoint } = firebase.firestore
export { Timestamp, GeoPoint }

// Define the collections
export const usersCollection = db.collection(
  process.env.NODE_ENV === 'production' ? 'users' : 'usersDevelopment'
)
export const usersDocumentsCollection = (userId: string): firebase.firestore.CollectionReference<IDocumentModel> => usersCollection
  .doc(userId)
  .collection(
    process.env.NODE_ENV === 'production' ? 'userDocuments' : 'userDocumentsDevelopment'
  ) as firebase.firestore.CollectionReference<IDocumentModel>
export const usersWorkReportsCollection = (userId: string): firebase.firestore.CollectionReference<IWorkReport> => usersCollection
  .doc(userId)
  .collection(
    process.env.NODE_ENV === 'production' ? 'workReports' : 'workReportsDevelopment'
  ) as firebase.firestore.CollectionReference<IWorkReport>
export const usersClockingInCollection = (userId: string): firebase.firestore.CollectionReference<IClockingInModel> => usersCollection
  .doc(userId)
  .collection(
    process.env.NODE_ENV === 'production' ? 'clockingIn' : 'clockingInDevelopment'
  ) as firebase.firestore.CollectionReference<IClockingInModel>
export const companyCommunicationsCollection = db.collection(
  process.env.NODE_ENV === 'production' ? 'companyCommunications' : 'companyCommunicationsDevelopment'
)
export const companyDocumentsCollection = db.collection(
  process.env.NODE_ENV === 'production' ? 'companyDocuments' : 'companyDocumentsDevelopment'
)
export const orderWorkingSitesCollection = (orderId: string): firebase.firestore.CollectionReference<IWorkingSiteModel> => ordersCollection
  .doc(orderId)
  .collection(
    process.env.NODE_ENV === 'production' ? 'workingSites' : 'workingSitesDevelopment'
  ) as firebase.firestore.CollectionReference<IWorkingSiteModel>
export const workingSitesCollectionGroup = db.collectionGroup(
  process.env.NODE_ENV === 'production' ? 'workingSites' : 'workingSitesDevelopment'
)
export const ordersCollection = db.collection(
  process.env.NODE_ENV === 'production' ? 'orders' : 'ordersDevelopment'
)
export const workingSitesDocumentsCollection = (orderId: string, workingSiteId: string): firebase.firestore.CollectionReference<IDocumentModel> => orderWorkingSitesCollection(orderId)
  .doc(workingSiteId)
  .collection(
    process.env.NODE_ENV === 'production' ? 'documents' : 'documentsDevelopment'
  ) as firebase.firestore.CollectionReference<IDocumentModel>
export const ordersWorkingSitesCollection = (orderId: string): firebase.firestore.CollectionReference<IWorkingSiteModel> => ordersCollection
  .doc(orderId)
  .collection(
    process.env.NODE_ENV === 'production' ? 'workingSites' : 'workingSitesDevelopment'
  ) as firebase.firestore.CollectionReference<IWorkingSiteModel>
export const eventChecksCollectionGroup = db.collectionGroup(
  process.env.NODE_ENV === 'production' ? 'eventsCheck' : 'eventsCheckDevelopment'
)
export const clockingInsCollectionGroup = db.collectionGroup(
  process.env.NODE_ENV === 'production' ? 'clockingIn' : 'clockingInDevelopment'
)
export const notificationsCollectionGroup = db.collectionGroup(
  process.env.NODE_ENV === 'production' ? 'notification' : 'notificationDevelopment'
)
export const workReportsCollectionGroup = db.collectionGroup(
  process.env.NODE_ENV === 'production' ? 'workReports' : 'workReportsDevelopment'
)
export const usersExpensesCollectionGroup = db.collectionGroup(
  process.env.NODE_ENV === 'production' ? 'expenses' : 'expensesDevelopment'
)
// Define the collections
export const eventsCollection = db.collection(
  process.env.NODE_ENV === 'production' ? 'events' : 'eventsDevelopment'
)
export const toolsCollection = db.collection(
  process.env.NODE_ENV === 'production' ? 'tools' : 'toolsDevelopment'
)
export const companyNotificationsCollection = db.collection(
  process.env.NODE_ENV === 'production' ? 'companyNotifications' : 'companyNotificationsDevelopment'
)
// Define the callable functions
export const createNewUserCallable = app.functions('europe-west3').httpsCallable(
  process.env.NODE_ENV === 'production' ? 'createUserCallable' : 'development-createUserCallable'
)
export const updateUserCallable = app.functions('europe-west3').httpsCallable(
  process.env.NODE_ENV === 'production' ? 'updateUserCallable' : 'development-updateUserCallable'
)
export const deleteUserCallable = app.functions('europe-west3').httpsCallable(
  process.env.NODE_ENV === 'production' ? 'deleteUserCallable' : 'development-deleteUserCallable'
)
export const createQrCodeCallable = app.functions('europe-west3').httpsCallable(
  process.env.NODE_ENV === 'production' ? 'createQrCodeCallable' : 'development-createQrCodeCallable'
)
export const createNewEventCallable = app.functions('europe-west3').httpsCallable(
  process.env.NODE_ENV === 'production' ? 'createEventCallable' : 'development-createEventCallable'
)
export const updateEventCallable = app.functions('europe-west3').httpsCallable(
  process.env.NODE_ENV === 'production' ? 'updateEventCallable' : 'development-updateEventCallable'
)
export const createNewCommunicationCallable = app.functions('europe-west3').httpsCallable(
  process.env.NODE_ENV === 'production' ? 'createCommunicationCallable' : 'development-createCommunicationCallable'
)
export const updateCommunicationCallable = app.functions('europe-west3').httpsCallable(
  process.env.NODE_ENV === 'production' ? 'updateCommunicationCallable' : 'development-updateCommunicationCallable'
)
export const deleteCommunicationCallable = app.functions('europe-west3').httpsCallable(
  process.env.NODE_ENV === 'production' ? 'deleteCommunicationCallable' : 'development-deleteCommunicationCallable'
)

// Define the storage folders
export const workingSitesFolder = storage.ref(
  process.env.NODE_ENV === 'production' ? 'workingSites' : 'workingSitesDevelopment'
)
export const workingSitesDocumentsFolder = (workingSiteId: string): firebase.storage.Reference => storage.ref(
  `${
      process.env.NODE_ENV === 'production' ? 'workingSites' : 'workingSitesDevelopment'
  }/${workingSiteId}/${
      process.env.NODE_ENV === 'production' ? 'documents' : 'documentsDevelopment'
  }`
)
export const companyCommunicationDocumentsFolder = storage.ref(
  process.env.NODE_ENV === 'production' ? 'companyCommunications' : 'companyCommunicationsDevelopment'
)
export const companyDocumentsFolder = storage.ref(
  process.env.NODE_ENV === 'production' ? 'companyDocuments' : 'companyDocumentsDevelopment'
)
export const userDocumentsFolder = (userId: string): firebase.storage.Reference => storage.ref(
  `${
      process.env.NODE_ENV === 'production' ? 'users' : 'usersDevelopment'
  }/${userId}/${
      process.env.NODE_ENV === 'production' ? 'documents' : 'documentsDevelopment'
  }`
)
export const chatGroupMessageAttachmentsFolder = (chatGroupId: string): firebase.storage.Reference => storage.ref(
  `${
      process.env.NODE_ENV === 'production' ? 'chatGroups' : 'chatGroupsDevelopment'
  }/${chatGroupId}`
)

export const uploadDocument = async (destination: firebase.storage.Reference, file: File, document: { id: string }): Promise<string> => {
  return new Promise((resolve, reject) => {
    const uploadTask = destination
      .child(document.id)
      .put(file)

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on('state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log('Upload is ' + progress + '% done')
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused')
            break
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running')
            break
        }
      },
      (error): void => {
        // Handle unsuccessful uploads
        console.log('Upload failed', error)
        reject(error)
      },
      async () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        const downloadUrl = await uploadTask.snapshot.ref.getDownloadURL()
        console.log('File available at', downloadUrl)
        resolve(downloadUrl)
      }
    )
  })
}

export const creditCardToolId = process.env.NODE_ENV === 'production' ? 'e7LKe4suvA3bF7AZm2iW' : 'btj7TMRrjwOrmWnYmAfv'
export const undeletableTools = [creditCardToolId]
