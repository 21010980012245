
import { WorkReportScope } from '@/enums'
import { IUserModel, IWorkingSiteModel, IWorkReport } from '@esse-group/shared'
import moment from 'moment'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'WorkReportCard',

  created: function () {
    (this as any).moment = moment
  },

  props: {
    scope: { type: Number as () => WorkReportScope, required: true },
    modelValue: { type: Object as () => IWorkReport, required: true }
  },

  data () {
    return {
      workingSiteName: '',
      userName: '',
      WorkReportScope
    }
  },

  async mounted () {
    if (
      (this as any).scope === WorkReportScope.user
      && (this as any).modelValue !== undefined
      && (this as any).modelValue.workingSiteId !== undefined
    ) {
      const fetchedWs = (this.$store.state.orders.workingSites as IWorkingSiteModel[]).find(w => w.id === (this as any).modelValue.workingSiteId)

      if (fetchedWs !== undefined) {
        this.workingSiteName = fetchedWs.name
      } else {
        try {
          const result = await this.$store.dispatch(
            'orders/getWorkingSite', this.modelValue.workingSiteId
          ) as IWorkingSiteModel
          console.log('getWorkingSiteName - result', { result })

          if (result !== undefined) {
            this.workingSiteName = result.name
          }
        } catch (error) {
          console.error('WorkReportCard - getWorkingSiteName - error getting the working site name', { error })
          console.error(error)
        }
      }
    }
    if (
      (this as any).scope === WorkReportScope.workingSite
      && (this as any).modelValue !== undefined
      && (this as any).modelValue.userId !== undefined
    ) {
      const fetchedWs = (this.$store.state.users.users as IUserModel[]).find(u => u.id === (this as any).modelValue.userId)

      if (fetchedWs !== undefined) {
        this.userName = `${fetchedWs.name} ${fetchedWs.surname}`
      } else {
        try {
          const result = await this.$store.dispatch(
            'users/getUser', this.modelValue.userId
          ) as IUserModel
          console.log('getUserName - result', { result })

          if (result !== undefined) {
            this.userName = `${result.name} ${result.surname}`
          }
        } catch (error) {
          console.error('WorkReportCard - getUserName - error getting the user name and surname', { error })
          console.error(error)
        }
      }
    }
  }
})
