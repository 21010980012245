/* eslint-disable operator-linebreak */
import { DocumentScope } from '../enums'
import { companyCommunicationsCollection, companyDocumentsCollection, usersDocumentsCollection, workingSitesDocumentsCollection } from '@/utilities'
import { IDocumentModel } from '@esse-group/shared'
import { ActionContext, Module } from 'vuex'
import { firestoreAction, vuexfireMutations } from '@xquick-code/vuexfire'
import { IDocumentsStoreState } from '../interfaces'
import firebase from 'firebase'

const documentCollectionDestination = (payload: { scope: DocumentScope, workingSiteId?: string, communicationId?: string, userId?: string }): firebase.firestore.CollectionReference | undefined => {
  switch (payload.scope) {
    case DocumentScope.companyDocument: return companyDocumentsCollection
    case DocumentScope.workingSiteDocument:
      if (payload.workingSiteId === undefined) { return undefined }
      return undefined //  TODO: Fix this workingSitesDocumentsCollection(payload.workingSiteId)
    case DocumentScope.userDocument:
      if (payload.userId === undefined) { return undefined }
      return usersDocumentsCollection(payload.userId)
    case DocumentScope.companyCommunicationDocument:
      if (payload.communicationId === undefined) { return undefined }
      return companyCommunicationsCollection
    default: return undefined
  }
}

export const DocumentsStore: Module<IDocumentsStoreState, unknown> = {
  namespaced: true,

  // setup the reactive todos property
  state: {
    document: undefined as IDocumentModel | undefined,
    documents: [] as IDocumentModel[]
  },

  mutations: {
    ...vuexfireMutations,

    setDocuments (state, { documents }) {
      console.log('DocumentsStore - setDocuments', { documents })
      state.documents = documents
    }
  },

  actions: {
    async fetchDocuments (
      context: ActionContext<IDocumentsStoreState, unknown>,
      payload: { workingSiteId?: string, communicationId?: string, scope: DocumentScope, requiresSignature: boolean }
    ): Promise<void> {
      console.log('DocumentsStore - fetchDocuments', { payload })

      const destination = documentCollectionDestination(payload)
      if (destination === undefined) { return }

      try {
        let documents: IDocumentModel[] = []
        if (payload.scope === DocumentScope.companyCommunicationDocument) {
          return
          // const comm = await destination.doc(payload.communicationId).get()
          // if (comm === undefined) { return }
          // const commData = (comm.data() as ICompanyCommunicationModel)
          // if (commData === undefined || commData.documents === undefined) { return }
          // documents = Object.values(commData.documents)
        } else {
          documents = (await destination.orderBy('createdTs', 'desc').get()).docs.map(d => d.data() as IDocumentModel)
        }
        console.log('DocumentsStore - fetchDocuments - result', { payload, documents })
        context.commit('setDocuments', { documents })
      } catch (error) {
        console.error('EventsStore - error fetching the documents', { payload, error })
      }
    },

    listDocuments: firestoreAction(async (context, payload: { scope: DocumentScope, workingSiteId?: string, approved?: boolean, requiresSignature: boolean }): Promise<number | undefined> => {
      console.debug('DocumentsStore - listDocuments', { payload })
      if (payload === undefined) { return }

      const destination = documentCollectionDestination(payload)
      if (destination === undefined) { return }

      try {
        let query: any = destination.orderBy('createdTs', 'desc')
        if (payload.approved !== undefined) {
          query = query.where('approved', '==', payload.approved)
        }

        return (await query.get()).size
      } catch (error) {
        console.error('DocumentsStore - error creating the documents', { payload, error })

        return undefined
      }
    }),

    createDocument: firestoreAction(async (
      context,
      payload: { workingSiteId?: string, communicationId?: string, scope: DocumentScope, document: IDocumentModel, requiresSignature: boolean }
    ): Promise<IDocumentModel | undefined> => {
      console.debug('DocumentsStore - createDocument', { payload })

      if (payload === undefined || payload.document.id === undefined) { return }
      const destination = documentCollectionDestination(payload)
      if (destination === undefined) { return }

      const doc: IDocumentModel = {
        ...payload.document,
        createdTs: (new Date()).valueOf() / 1000
      }

      try {
        if (payload.scope === DocumentScope.companyCommunicationDocument) {
          await destination.doc(payload.communicationId).update({
            [`documents.${doc.id}`]: doc
          })
        } else {
          await destination.doc(doc.id).set(doc)
        }

        context.dispatch('fetchDocuments', payload)
      } catch (error) {
        console.error('DocumentsStore - error creating the document', { payload, error })

        return undefined
      }

      console.debug('DocumentsStore - createDocument - success')

      return doc
    }),

    updateDocument: firestoreAction(async (
      context,
      payload: { workingSiteId?: string, communicationId?: string, scope: DocumentScope, document: Partial<IDocumentModel>, requiresSignature: boolean }
    ): Promise<Partial<IDocumentModel> | undefined> => {
      console.debug('DocumentsStore - updateDocument', { payload })
      if (payload === undefined || payload.document.id === undefined) { return }

      const destination = documentCollectionDestination(payload)
      if (destination === undefined) { return }

      const doc: Partial<IDocumentModel> = {
        ...payload.document,
        updatedTs: (new Date()).valueOf() / 1000
      }

      try {
        if (payload.scope === DocumentScope.companyCommunicationDocument) {
          await destination.doc(payload.communicationId).update({
            [`documents.${doc.id}`]: doc
          })
        } else {
          await destination.doc(doc.id).update(doc)
        }

        context.dispatch('fetchDocuments', payload)
      } catch (error) {
        console.error('EventsStore - error updating the document', { payload, error })

        return undefined
      }

      console.debug('DocumentsStore - updateDocument - success')

      return doc
    }),

    deleteDocument: firestoreAction(async (
      context,
      payload: { documentId: string, communicationId?: string, userId?: string, workingSiteId?: string, scope: DocumentScope, requiresSignature: boolean }
    ): Promise<boolean> => {
      console.debug('DocumentsStore - deleteDocument', { payload })
      if (payload === undefined || payload.documentId === undefined) { return false }

      const destination = documentCollectionDestination(payload)
      if (destination === undefined) { return false }

      try {
        await destination.doc(payload.documentId).delete()
        if (payload.scope === DocumentScope.companyCommunicationDocument) {
          await destination.doc(payload.communicationId).update({
            [`documents.${payload.documentId}`]: firebase.firestore.FieldValue.delete()
          })
        } else {
          await destination.doc(payload.documentId).delete()
        }
        context.dispatch('fetchDocuments', payload)
      } catch (error) {
        console.error('DocumentsStore - error removing the working site document', { context, workingSiteId: payload.workingSiteId, error })

        return false
      }

      console.debug('DocumentsStore - deleteDocument - success')

      return true
    }),

    // Document
    getDocument: firestoreAction(async (
      context,
      payload: { documentId: string, communicationId?: string, userId?: string, workingSiteId?: string, scope: DocumentScope, requiresSignature: boolean }
    ): Promise<IDocumentModel | undefined> => {
      console.debug('DocumentsStore - getDocument', payload)
      if (payload === undefined || payload.documentId === undefined) { return undefined }

      const destination = documentCollectionDestination(payload)
      if (destination === undefined) { return undefined }

      try {
        if (payload.scope === DocumentScope.companyCommunicationDocument) {
          // TODO: DO this
          /* await destination.doc(payload.communicationId).update({
            [`documents.${doc.id}`]: doc
          }) */
        } else {
          const data = await destination.doc(payload.documentId).get()
          if (data.exists) {
            return data.data() as IDocumentModel
          }
        }
      } catch (error) {
        console.error('DocumentsStore - getDocument - error removing the working site document', { context, workingSiteId: payload.workingSiteId, error })

        return undefined
      }

      console.debug('DocumentsStore - getDocument - success')

      return undefined
    }),

    getWorkingSiteDocument: firestoreAction(async (context, payload) => {
      console.debug('getWorkingSiteDocument', payload)
      /*
      const doc = await workingSitesDocumentsCollection(payload.workingSiteId)
        .doc(payload.documentId)
        .get()

      return doc.exists ? doc.data() : undefined */
    }),

    getCompanyDocument: firestoreAction(async (context, payload) => {
      console.debug('getCompanyDocument', payload)

      const doc = await companyDocumentsCollection
        .doc(payload.documentId)
        .get()

      return doc.exists ? doc.data() : undefined
    })
  }
}
