
import { defineComponent } from 'vue'
import ModalDialog from './ModalDialog.vue'
import PrimaryButton from '../buttons/PrimaryButton.vue'
import Loader from '../Loader.vue'
import { AlertType } from '@/enums'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { IWorkingSiteModel } from '@esse-group/shared'
import Toggle from '@/components/Toggle.vue'
import Checkbox from '@/components/Checkbox.vue'
import { LeftArrow, SuccessIcon } from '@/components/icons'
import { DatePicker } from 'v-calendar'

export default defineComponent({
  name: 'AddEditOrderModalDialog',

  emits: ['close'],

  components: {
    ModalDialog, PrimaryButton, Loader, LeftArrow, SuccessIcon, Toggle, Checkbox, DatePicker
  },

  props: {
    modelValue: {
      type: Object as () => IWorkingSiteModel,
      required: false,
      default: undefined
    }
  },

  setup () {
    return { v$: useVuelidate() }
  },

  mounted: function (): void {
    if (this.modelValue !== undefined) {
      this.step1.id = this.modelValue.id
      this.step1.name = this.modelValue.name ?? ''
      this.step1.orderNumber = this.modelValue.orderNumber ?? ''
      this.step1.customer = this.modelValue.customer ?? ''
    }
  },

  data () {
    return {
      step: 0,
      step1: {
        id: '',
        name: '',
        customer: '',
        orderNumber: ''
      },
      loading: false
    }
  },

  computed: {
    // eslint-disable-next-line eqeqeq
    isEditing: function (): boolean { return this.modelValue != undefined },
    title: function (): string {
      if (this.step > 1) { return '' }
      return this.isEditing
        ? 'Stai aggiornando questo cantiere'
        : 'Stai aggiungendo un nuovo cantiere'
    },
    subtitle: function (): string {
      if (this.step > 1) { return '' }
      if (this.isEditing) { return 'Modifica le seguenti informazioni' }
      switch (this.step) {
        case 0: return 'Aggiungi le seguenti informazioni'
        default: return ''
      }
    },
    orderId: function () {
      return this.$route.params.orderId
    }
  },

  validations () {
    return {
      step1: {
        name: { required }
      }
    }
  },

  methods: {
    onStep1: function () {
      console.log('onStep1')
      this.step = 0
    },
    onClose: function () {
      this.$emit('close')
    },

    create: async function () {
      this.loading = true

      // Validate the fields
      this.v$.step1.$touch()
      if (this.v$.step1.$error) {
        return
      }

      try {
        const result = await this.$store.dispatch(
          'orders/createNewOrder',
          {
            name: this.step1.name,
            customer: this.step1.customer,
            orderNumber: this.step1.orderNumber
          }
        )

        console.debug('AddEditOrderModalDialog - create', { result })
        // eslint-disable-next-line eqeqeq
        if (result == undefined) {
          await this.$store.dispatch('alerts/create', {
            message: 'creazione cantiere fallita',
            type: AlertType.error
          })
          return
        }

        await this.$store.dispatch('alerts/create', {
          message: 'creazione cantiere riuscita',
          type: AlertType.success
        })
        this.loading = false
        this.$emit('close')
        this.$router.push(`/orders/${result.id}/order-details`)
      } catch (error) {
        console.error('error creating the working site', error)
        await this.$store.dispatch('alerts/create', {
          message: 'creazione cantiere fallita',
          type: AlertType.error
        })
      }
    },

    // Update
    update: async function () {
      this.loading = true

      // Validate the fields
      this.v$.$touch()
      if (this.v$.$error) {
        this.loading = false
        return
      }

      try {
        const result = await this.$store.dispatch(
          'orders/updateOrder',
          {
            id: this.step1.id,
            name: this.step1.name,
            customer: this.step1.customer,
            orderNumber: this.step1.orderNumber
          }
        )

        this.loading = false
        if (!result) {
          await this.$store.dispatch('alerts/create', {
            message: 'aggiornamento cantiere fallito',
            type: AlertType.error
          })
          return
        }

        await this.$store.dispatch('orders/getOrder', this.step1.id)

        await this.$store.dispatch('alerts/create', {
          message: 'aggiornamento cantiere riuscito',
          type: AlertType.success
        })
        // Add a delay to ensure that the Algolia Index is updated
        setTimeout(() => {
          // Then we reload the users
          this.$store.dispatch('orders/reloadWorkingSites')
        }, 5000)
      } catch (error) {
        console.error('error updating the working site', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', {
          message: 'aggiornamento cantiere fallito',
          type: AlertType.error
        })
      }
      this.$emit('close')
    }
  }
})
