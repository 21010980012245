
import { defineComponent } from 'vue'
import { BlueDocumentIcon } from '@/components/icons'
import { IDocumentModel } from '@esse-group/shared'

export default defineComponent({
  name: 'NotificationDocument',

  components: {
    BlueDocumentIcon
  },

  props: {
    modelValue: {
      type: Object as () => IDocumentModel,
      required: true
    }
  },

  data () {
    return {
      deleteRelatedDocument: false
    }
  },

  methods: {
    openAttachment: function () {
      console.log('openAttachment', { modelValue: this.modelValue })
      window.open(this.modelValue.documentUrl, '_blank')
    }
  },

  computed: {
    mimeTypeSlag: function () {
      switch (this.modelValue.mimeType) {
        case 'image/jpg':
        case 'image/jpeg': return 'JPG'
        case 'application/pdf': return 'PDF'
        case 'image/png': return 'PNG'
        default: return 'FILE'
      }
    }
  }
})
