import { createStore } from 'vuex'
import { vuexfireMutations } from '@xquick-code/vuexfire'
import { IStoreState } from '../interfaces'
import { AlertsStore } from './alerts.store'
import { AuthenticationStore } from './authentication.store'
import { ClockingInsStore } from './clocking-ins.store'
import { CompanyNotificationsStore } from './company-notifications.store'
import { UsersStore } from './users.store'
import { WorkReportsStore } from './work-reports.store'
import { DocumentsStore } from './documents.store'
import { OrdersStore } from './orders.store'

export default createStore<IStoreState>({
  modules: {
    alerts: AlertsStore,
    auth: AuthenticationStore,
    clockingIns: ClockingInsStore,
    companyNotifications: CompanyNotificationsStore,
    users: UsersStore,
    workReports: WorkReportsStore,
    documents: DocumentsStore,
    orders: OrdersStore
  },

  mutations: vuexfireMutations
})
