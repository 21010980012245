
import { defineComponent } from 'vue'
import { PrimaryButton, Loader } from '@/components'
import { AddEditClockingInDialog, DeleteClockinInDialog } from '@/components/dialogs'
import { ClockingInScope } from '@/enums'
import { IUserModel, IWorkingSiteModel } from '@esse-group/shared'
import moment from 'moment'

export default defineComponent({
  name: 'WorkingSiteClockingInsSection',

  components: {
    AddEditClockingInDialog, PrimaryButton, Loader, DeleteClockinInDialog
  },

  props: {
    id: { type: String, required: true },
    scope: {
      type: Number as () => ClockingInScope,
      required: true
    },
    user: { type: Object as () => IUserModel | undefined, required: false },
    workingSite: { type: Object as () => IWorkingSiteModel | undefined, required: false }
  },

  data () {
    return {
      isShowingClockingInDialog: false,
      clockingIns: [],
      moment,
      isDeleting: undefined,
      loading: false,
      isShowingDeleteDialog: false,
      document
    }
  },

  async mounted () {
    this.loadClockingIns()
  },

  computed: {
    facetFilters: function (): string[] {
      switch (this.scope) {
        case ClockingInScope.workingSite: return [`workingSiteId:${this.id}`]
        case ClockingInScope.user: return [`userId:${this.id}`]
        default: return []
      }
    }
  },

  methods: {
    loadClockingIns: async function () {
      this.clockingIns = await this.$store.dispatch('clockingIns/instantSearchClockingIns', {
        facetFilters: this.facetFilters,
        hitsPerPage: 10,
        page: 0
      })
      console.debug('searchUsers', { clockingIns: this.clockingIns })
    },
    deleteDocument: function (clockingIn: any) {
      this.document = clockingIn
      this.isShowingDeleteDialog = true
    },
    reloadClockingIns: function (isDeleting: boolean) {
      this.loading = true
      setTimeout(() => {
        this.loadClockingIns()
        console.log('ClockingIns Reloaded')
        this.loading = false
      }, 6000)
    },
    goToOrder: function (orderId : any) {
      this.$router.push(`/orders/${orderId}`)
    },
    goToWorkingSite: function (orderId : any, workingSiteId: any) {
      this.$router.push(`/orders/${orderId}/${workingSiteId}`)
    },
    goToUser: function (userId : any) {
      this.$router.push(`/users/${userId}`)
    }
  }
})
