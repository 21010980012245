/* eslint-disable eqeqeq */
import moment from 'moment'
import * as ExcelJS from 'exceljs'
import { capitalizeFirstLetter } from './capitalize-first-letter'
import { ITimeReportEntry } from '@/interfaces'
import { IUserModel, UserRole } from '@esse-group/shared'

/**
 * Autofit columns by width
 *
 * @param worksheet {ExcelJS.Worksheet}
 * @param minimalWidth
 */
const autoWidth = (worksheet, minimalWidth = 10) => {
  worksheet.columns.forEach((column) => {
    let maxColumnLength = 0
    column.eachCell({ includeEmpty: true }, (cell) => {
      maxColumnLength = Math.max(
        maxColumnLength,
        minimalWidth,
        cell.value ? cell.value.toString().length : 0
      )
    })
    column.width = maxColumnLength + 2
  })
}

export const timeReportExcelCreator = async (params: {
  filterDates?: { start: Date, end: Date }
  timeReportEntries?: ITimeReportEntry[],
  currentUser?: IUserModel | null | undefined
}): Promise<ExcelJS.Workbook | undefined> => {
  console.debug('userTimeReportComputer', { params })
  // eslint-disable-next-line eqeqeq
  if (params.filterDates == undefined || params.timeReportEntries == undefined) { return }

  const startMoment = moment(params.filterDates.start)
  const endMoment = moment(params.filterDates.end)

  /* generate workbook and add the worksheet */
  const workbook: ExcelJS.Workbook = new ExcelJS.Workbook()
  workbook.creator = params.currentUser ? `${params.currentUser.name} ${params.currentUser.surname}` : ''
  workbook.created = new Date()

  const isSuperAdmin = params.currentUser != undefined && params.currentUser.role >= UserRole.SUPER_ADMIN

  const sheet = workbook.addWorksheet('Report', {
    pageSetup: {
      paperSize: 9,
      orientation: 'landscape',
      printArea: `A1:${isSuperAdmin ? 'O' : 'I'}${4 + params.timeReportEntries.length}`,
      printTitlesRow: '1:4',
      printTitlesColumn: `A:${isSuperAdmin ? 'O' : 'I'}`,
      fitToWidth: 1,
      fitToHeight: undefined
    }
  })

  const months = endMoment.diff(startMoment, 'months', true)
  console.debug(`downloadSelectedReport - diffs - ${months}`)

  sheet.addRows([
    ['Periodo', capitalizeFirstLetter(startMoment.format('DD/MM/YYYY')), endMoment.format('DD/MM/YYYY')],
    ['']
  ])
  sheet.addRows(
    isSuperAdmin
      ? [
          ['Cantiere', 'Blocco', 'Cognome', 'Nome', 'Agenzia', '', 'Data / Ora', '', '', '', '', '', '', 'Conteggi', '', ''],
          ['', '', '', '', '', 'Minuti di pausa previsti', 'Data', 'Ora ingresso', 'Ora uscita', 'Ora ingresso reale', 'Ora uscita reale', 'Ora ingresso reale arrotondata', 'Ora uscita reale arrotondata', 'Ore lavorate', 'Ore extra', 'Totale']
        ]
      : [
          ['Cantiere', 'Blocco', 'Cognome', 'Nome', 'Agenzia', '', 'Data / Ora', '', '', 'Ore lavorate'],
          ['', '', '', '', '', 'Minuti di pausa previsti', 'Data', 'Ora ingresso', 'Ora uscita', 'Ore lavorate']
        ]
  )
  const row1 = sheet.getRow(1)
  row1.getCell(1).font = { name: 'Lato', color: { argb: 'FF000000' }, family: 2, size: 14 }
  row1.getCell(2).font = { name: 'Lato', color: { argb: 'FF000000' }, family: 2, size: 14 }
  row1.getCell(3).font = { name: 'Lato', color: { argb: 'FF000000' }, family: 2, size: 14 }

  const row3 = sheet.getRow(3)
  const row4 = sheet.getRow(4)
  for (let i = 1; i < (isSuperAdmin ? 16 : 10); i++) {
    const cell1 = row3.getCell(i)
    const cell2 = row4.getCell(i)

    const borderStyle: any = { style: 'thin', color: { argb: 'FF000000' } }

    cell1.alignment = { horizontal: 'center', vertical: 'middle' }
    cell1.fill = cell2.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF434343' } }
    cell1.font = { name: 'Lato', color: { argb: 'FFFFFFFF' }, family: 2, size: i > 5 ? 14 : 12 }
    cell1.border = { bottom: borderStyle, left: borderStyle, right: borderStyle, top: borderStyle }

    cell2.alignment = { horizontal: 'left', vertical: 'middle' }
    cell2.font = { name: 'Lato', color: { argb: 'FFFFFFFF' }, family: 2, size: 12 }
    cell2.border = { bottom: borderStyle, left: borderStyle, right: borderStyle, top: borderStyle }
  }
  autoWidth(sheet, 8)

  for (const e of params.timeReportEntries) {
    console.log('e', { e })
    let row = [
      e.orderName || '', // Cantiere
      e.workingSiteName || '', // Blocco
      e.userSurname || '', // Cognome
      e.userName || '', // Nome
      e.agency || '', // Agenzia
      e.userBreakTimeMinutes || '', // 'Ore pausa previste'
      e.day ? moment(e.day as any).format('DD/MM/YYYY') : '', // Data
      e.inAdjusted ? moment(e.inAdjusted as any).format('HH:mm') : '', // Ora ingresso
      e.outAdjusted ? moment(e.outAdjusted as any).format('HH:mm') : '' // Ora uscita
    ]

    if (isSuperAdmin) {
      row = [...row,
        e.in ? moment(e.in as any).format('HH:mm') : '', // Ora ingresso reale
        e.out ? moment(e.out as any).format('HH:mm') : '', // Ora uscita reale
        e.inRounded ? moment(e.inRounded as any).format('HH:mm') : '', // Ora ingresso reale arrotondata
        e.outRounded ? moment(e.outRounded as any).format('HH:mm') : '' // Ora uscita reale arrotondata
      ]
    }
    row.push(e.workingHoursRounded ? e.workingHoursRounded / 60 : e.workingHoursRounded) // Ore lavorate

    if (isSuperAdmin) {
      row = [...row,
        e.overtimeRoundedMinutes ? e.overtimeRoundedMinutes / 60 : e.overtimeRoundedMinutes, // Ore extra
        e.workingHoursRounded || e.overtimeRoundedMinutes
          ? ((e.workingHoursRounded || 0) + (e.overtimeRoundedMinutes || 0)) / 60
          : '' // Totale
      ]
    }

    sheet.addRow(row)
  }
  sheet.mergeCells(3, 1, 4, 1)
  sheet.mergeCells(3, 2, 4, 2)
  sheet.mergeCells(3, 3, 4, 3)
  sheet.mergeCells(3, 4, 4, 4)
  sheet.mergeCells(3, 5, 4, 5)

  sheet.mergeCells(3, 6, 3, isSuperAdmin ? 12 : 8)
  if (isSuperAdmin) {
    sheet.mergeCells(3, 13, 3, 15)
  } else {
    sheet.mergeCells(3, 9, 4, 9)
  }

  return workbook
}
