
import { WeekDay } from '@/enums/week-day'
import { ITimingModel } from '@esse-group/shared'
import { defineComponent } from 'vue'
import moment from 'moment'

export default defineComponent({
  name: 'CustomHourPicker',

  emits: ['update:modelValue'],

  props: {
    modelValue: {
      type: Object as () => ITimingModel[],
      required: true,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => [
        { closingTimeHours: 12, closingTimeMinutes: 0, openingTimeHours: 8, openingTimeMinutes: 30, weekDay: WeekDay.TUESDAY },
        { closingTimeHours: 12, closingTimeMinutes: 0, openingTimeHours: 8, openingTimeMinutes: 30, weekDay: WeekDay.WEDNESDAY },
        { closingTimeHours: 12, closingTimeMinutes: 0, openingTimeHours: 8, openingTimeMinutes: 30, weekDay: WeekDay.THURSDAY },
        { closingTimeHours: 12, closingTimeMinutes: 0, openingTimeHours: 8, openingTimeMinutes: 30, weekDay: WeekDay.FRIDAY },
        { closingTimeHours: 12, closingTimeMinutes: 0, openingTimeHours: 8, openingTimeMinutes: 30, weekDay: WeekDay.SATURDAY },
        { closingTimeHours: 12, closingTimeMinutes: 0, openingTimeHours: 8, openingTimeMinutes: 30, weekDay: WeekDay.SUNDAY }
      ]
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      rows: [0, 3],
      moment,
      weekDays: [
        { name: 'Lunedì', type: WeekDay.MONDAY },
        { name: 'Martedì', type: WeekDay.TUESDAY },
        { name: 'Mercoledì', type: WeekDay.WEDNESDAY },
        { name: 'Giovedì', type: WeekDay.THURSDAY },
        { name: 'Venerdì', type: WeekDay.FRIDAY },
        { name: 'Sabato', type: WeekDay.SATURDAY },
        { name: 'Domenica', type: WeekDay.SUNDAY }
      ],
      options: [
        { type: 'Regolare', value: 'Continuous' },
        { type: 'Chiuso', value: 'Closed' }
      ]
    }
  },

  methods: {
    getTime (weekDay: number, row: number): string | undefined {
      // eslint-disable-next-line eqeqeq
      if (this.modelValue == undefined) { return undefined }
      const entries: ITimingModel[] = this.modelValue.filter(t => t.weekDay === weekDay)
      if (entries === undefined || entries.length === 0 || !moment) { return undefined }

      switch (row) {
        case 0:
          if (entries[0] === undefined) { return undefined }

          return moment()
            .set('minutes', entries[0].openingTimeMinutes)
            .set('hours', entries[0].openingTimeHours)
            .format('HH:mm')

        case 1:
          if (entries[0] === undefined || this.isAllDayShift(weekDay)) { return undefined }

          return moment()
            .set('minutes', entries[0].closingTimeMinutes)
            .set('hours', entries[0].closingTimeHours)
            .format('HH:mm')

        case 2:
          if (entries[1] === undefined || this.isAllDayShift(weekDay)) { return undefined }

          return moment()
            .set('minutes', entries[1].openingTimeMinutes)
            .set('hours', entries[1].openingTimeHours)
            .format('HH:mm')

        case 3:
          // eslint-disable-next-line eqeqeq
          if (entries[0] != undefined && this.isAllDayShift(weekDay)) {
            return moment()
              .set('minutes', entries[0].closingTimeMinutes)
              .set('hours', entries[0].closingTimeHours)
              .format('HH:mm')
          }
          if (entries[1] === undefined) { return undefined }

          return moment()
            .set('minutes', entries[1].closingTimeMinutes)
            .set('hours', entries[1].closingTimeHours)
            .format('HH:mm')

        default: return undefined
      }
    },

    getType (weekDay: number): string {
      let type = 'Normal'

      // eslint-disable-next-line eqeqeq
      if (this.modelValue == undefined) { return type }
      const weekDayTimings: ITimingModel[] = this.modelValue.filter(t => t.weekDay === weekDay)

      // eslint-disable-next-line eqeqeq
      if (weekDayTimings.length == 0) {
        type = 'Closed'
        // eslint-disable-next-line eqeqeq
      } else if (weekDayTimings.length == 1) {
        type = 'Continuous'
      }

      return type
    },

    onChangeSelect: async function (event: Event, weekDay: any) {
      console.debug('CustomHourPicker - onChangeSelect', { event, weekDay })
      event.preventDefault()

      // eslint-disable-next-line eqeqeq
      if (event.target == undefined || (event.target as any).value == undefined) { return }

      switch ((event.target as any).value) {
        case 'Normal': {
          console.log('onChangeSelect, Normal')

          this.onToggleClosingShift(false, weekDay.type)

          break
        }
        case 'Continuous': {
          console.log('onChangeSelect, Continuous')

          this.onToggleAllDayShift(weekDay.type)

          break
        }
        case 'Closed': {
          console.log('onChangeSelect, Closed')

          this.onToggleClosingShift(true, weekDay.type)

          break
        }
      }
    },

    onSetForAllWeekDaysClick: function (event: Event, weekDay: number): void {
      console.log('onSetForAllWeekDaysClick', event, weekDay)

      const weekDayTiming: ITimingModel[] = this.modelValue.filter(v => v.weekDay === weekDay)
      const modelValue: ITimingModel[] = []

      for (const wd of this.weekDays) {
        let editWeekDayTiming: ITimingModel[] = this.modelValue.filter(v => v.weekDay === wd.type)

        editWeekDayTiming = weekDayTiming.map(
          (ot: ITimingModel): ITimingModel => {
            const otCloned: ITimingModel = { ...ot }

            otCloned.weekDay = wd.type
            return otCloned
          }
        )

        modelValue.push(...editWeekDayTiming)
      }

      this.$emit('update:modelValue', modelValue)
    },

    onTimepickerFieldChange: function (event: Event, weekDay: number, row: number): void {
      console.log('onTimepickerFieldChange', event, weekDay, row)

      // eslint-disable-next-line eqeqeq
      if (this.modelValue == undefined) { return }
      if (this.modelValue.filter(v => v.weekDay === weekDay).length === 0 || !moment) { return }

      const time: moment.Moment = moment((event.target as any).value, 'HH:mm')
      if (!moment.isMoment(time) || !time.isValid()) {
        return
      }

      const weekDayTiming: ITimingModel[] = this.modelValue.filter(v => v.weekDay === weekDay)

      switch (row) {
        case 0:
          if (weekDayTiming[0] === undefined) { return }

          weekDayTiming[0].openingTimeHours = Number(time.format('HH'))
          weekDayTiming[0].openingTimeMinutes = Number(time.format('mm'))

          break

        case 1:
          if (weekDayTiming[0] === undefined || this.isAllDayShift(weekDay)) { return }

          weekDayTiming[0].closingTimeHours = Number(time.format('HH'))
          weekDayTiming[0].closingTimeMinutes = Number(time.format('mm'))

          break

        case 2:
          if (weekDayTiming[1] === undefined || this.isAllDayShift(weekDay)) { return }

          weekDayTiming[1].openingTimeHours = Number(time.format('HH'))
          weekDayTiming[1].openingTimeMinutes = Number(time.format('mm'))

          break

        case 3:
          if (weekDayTiming[0] !== undefined && this.isAllDayShift(weekDay)) {
            weekDayTiming[0].closingTimeHours = Number(time.format('HH'))
            weekDayTiming[0].closingTimeMinutes = Number(time.format('mm'))

            break
          }
          if (weekDayTiming[1] === undefined) { return }

          weekDayTiming[1].closingTimeHours = Number(time.format('HH'))
          weekDayTiming[1].closingTimeMinutes = Number(time.format('mm'))

          break

        default: return
      }

      this.$emit('update:modelValue', [
        ...this.modelValue.filter(v => v.weekDay !== weekDay),
        ...weekDayTiming
      ])
    },

    isAllDayShift: function (weekDay: number): boolean | undefined {
      if (this.modelValue === undefined) { return undefined }

      return this.modelValue.filter(v => v.weekDay === weekDay).length === 1
    },

    onToggleAllDayShift (weekDay: number): void {
      console.log('onToggleAllDayShift', event)

      let weekDayTimings = this.modelValue.filter(v => v.weekDay === weekDay)

      const time: moment.Moment = moment().set({ hour: 0, minute: 0 })

      if (weekDayTimings.length > 0) {
        const opening: ITimingModel = {
          openingTimeHours: weekDayTimings[0].openingTimeHours,
          openingTimeMinutes: weekDayTimings[0].openingTimeMinutes,
          closingTimeHours: weekDayTimings[1].closingTimeHours,
          closingTimeMinutes: weekDayTimings[1].closingTimeMinutes,
          weekDay: weekDayTimings[0].weekDay
        }

        weekDayTimings = []
        weekDayTimings.push({ ...opening })
      } else {
        weekDayTimings.push({
          openingTimeHours: Number(time.format('HH')),
          openingTimeMinutes: Number(time.format('mm')),
          closingTimeHours: Number(time.format('HH')),
          closingTimeMinutes: Number(time.format('mm')),
          weekDay
        })
      }

      this.$emit('update:modelValue', [
        ...this.modelValue.filter(v => v.weekDay !== weekDay),
        ...weekDayTimings
      ])
    },

    onToggleClosingShift: function (event: boolean, weekDay: number): void {
      console.log('onToggleClosingShift', event)

      let weekDayTimings = [] as ITimingModel[]

      if (!event) {
        weekDayTimings = [
          this.getEmptyOpeningTime(weekDay, 0),
          this.getEmptyOpeningTime(weekDay, 1)
        ]
      }

      this.$emit('update:modelValue', [
        ...this.modelValue.filter(v => v.weekDay !== weekDay),
        ...weekDayTimings
      ])
    },

    getEmptyOpeningTime: function (weekDay: number, slice: 0 | 1): ITimingModel {
      return {
        closingTimeHours: slice === 0 ? 12 : 18,
        closingTimeMinutes: 0,
        openingTimeHours: slice === 0 ? 8 : 14,
        openingTimeMinutes: 0,
        // tslint:disable-next-line:no-any
        weekDay: (weekDay as any)
      }
    }
  }
})
