
import { defineComponent } from 'vue'
import { EsseGroupLogo, MenuButton, SecondaryButton, Loader } from '@/components'
import packageJson from '@/../package.json'

export default defineComponent({
  name: 'Home',

  components: {
    EsseGroupLogo, MenuButton, SecondaryButton, Loader
  },

  data: function () {
    return {
      loading: false,
      showMenuDropdown: false,
      version: packageJson.version
    }
  },

  methods: {
    onLogout: async function () {
      console.log('Home - onLogout')
      this.loading = true
      await this.$store.dispatch('auth/signOut')
      await this.$router.replace('/auth/login')
      this.loading = false
    },
    onMap: async function () {
      console.log('Home - onMap')
      await this.$router.push('/map')
    }
  }
})
