/* eslint-disable operator-linebreak */
import { IAlgoliaWorkingSiteModel, IOrderModel, IQRCodeWorkingSiteModel, IWorkingSiteModel } from '@esse-group/shared'
import { createQrCodeCallable, db, ordersWorkingSitesCollection, workingSitesIndex } from '@/utilities'
import { ActionContext, Module } from 'vuex'
import { firestoreAction, vuexfireMutations } from '@xquick-code/vuexfire'
import { IOrdersStoreState } from '../interfaces'
import { v4 as UUID } from 'uuid'
import { ordersIndex } from '@/utilities/algolia-utility'
import { ordersCollection } from '@/utilities/firebase-utility'
import { ITimingModel } from '@esse-group/shared/lib/interfaces/timing-model.interface'
import store from '.'
import { getOverlappingDaysInIntervals } from 'date-fns'

const hitsPerPage = 50

export const OrdersStore: Module<IOrdersStoreState, unknown> = {
  namespaced: true,

  // setup the reactive todos property
  state: {
    workingSite: undefined as IWorkingSiteModel | undefined,
    workingSites: [] as IWorkingSiteModel[],
    orders: [] as IOrderModel[],
    order: undefined as IOrderModel | undefined,
    workingSitesSearchQuery: '',
    ordersSearchQuery: '',
    workingSitesPage: 0,
    ordersPage: 0
  },

  mutations: {
    ...vuexfireMutations,

    setWorkingSites (state, { workingSites, page }) {
      console.log('OrdersStore - setWorkingSites', { workingSites, page })
      state.orders = workingSites
      state.ordersPage = page
    },
    setOrders (state, { orders, page }) {
      console.log('OrdersStore - setOrders', { orders, page })
      state.orders = orders
      state.ordersPage = page
    },
    setWorkingSitesSearchQuery (state, workingSitesSearchQuery) {
      state.ordersSearchQuery = workingSitesSearchQuery
    },
    setOrdersSearchQuery (state, ordersSearchQuery) {
      state.ordersSearchQuery = ordersSearchQuery
    },
    resetWorkingSite (state, workingSites) {
      state.orders = workingSites
      state.ordersSearchQuery = ''
      state.ordersPage = 0
    },
    resetWorkingSites (state, workingSites) {
      state.workingSites = workingSites
    },
    resetOrders (state, orders) {
      state.orders = orders
      state.ordersSearchQuery = ''
      state.ordersPage = 0
    },
    addOrder (state, order) {
      state.orders.push(order)
    },
    resetOrder (state, order) {
      state.order = order
    }
  },

  actions: {
    async reloadWorkingSites (
      context: ActionContext<IOrdersStoreState, unknown>,
      params?: {
        facetFilters?: string | readonly string[] | readonly (readonly string[])[] | undefined,
        hitsPerPage?: number
      }
    ): Promise<boolean> {
      console.log('OrdersStore - reloadWorkingSites', { params })
      try {
        const result = await workingSitesIndex.search(
          '',
          {
            // eslint-disable-next-line eqeqeq
            hitsPerPage: params != undefined && params.hitsPerPage != undefined ? params.hitsPerPage : hitsPerPage,
            // eslint-disable-next-line eqeqeq
            facetFilters: params != undefined ? params.facetFilters : undefined,
            cacheable: false
          }
        )
        console.log('OrdersStore - result', { result })
        context.commit('resetWorkingSite', result.hits)
      } catch (error) {
        console.error('OrdersStore - error searching inside the workingSites index', { context, error })
        return false
      }

      return true
    },

    async reloadOrders (
      context: ActionContext<IOrdersStoreState, unknown>,
      params?: {
        facetFilters?: string | readonly string[] | readonly (readonly string[])[] | undefined,
        hitsPerPage?: number
      }
    ): Promise<boolean> {
      console.log('OrdersStore - reloadOrders', { params })
      try {
        const result = await ordersIndex.search(
          '',
          {
            // eslint-disable-next-line eqeqeq
            // hitsPerPage: params != undefined && params.hitsPerPage != undefined ? params.hitsPerPage : hitsPerPage,
            // eslint-disable-next-line eqeqeq
            facetFilters: params != undefined && params.facetFilters != undefined
              ? (
                  typeof params.facetFilters === 'string'
                    ? `${params.facetFilters} AND isArchived:-true`
                    : [...params.facetFilters, 'isArchived:-true'] as any
                )
              : 'isArchived:-true',
            cacheable: false
          }
        )
        console.log('OrdersStore - result', { result })
        context.commit('resetOrders', result.hits)
      } catch (error) {
        console.error('OrdersStore - error searching inside the orders index', { context, error })
        return false
      }

      return true
    },

    async paginateWorkingSites (
      context: ActionContext<IOrdersStoreState, unknown>,
      params: { forceReload?: boolean, query: string }
    ): Promise<void> {
      console.log('OrdersStore - paginateWorkingSites', params)
      context.commit('setWorkingSitesSearchQuery', params.query)

      const page = params.query === context.state.ordersSearchQuery && !params.forceReload
        ? context.state.ordersPage + 1
        : 0

      try {
        const result = await workingSitesIndex.search(params.query, { hitsPerPage, page, cacheable: false })
        console.log('OrdersStore - paginateWorkingSites - result', result)
        context.commit(
          'setWorkingSites',
          {
            page,
            workingSites: params.forceReload === true ? result.hits : [...context.state.orders, ...result.hits]
          }
        )
      } catch (error) {
        console.error('OrdersStore - error searching inside the workingSites index', { context, params, error })
      }
    },

    async paginateOrders (
      context: ActionContext<IOrdersStoreState, unknown>,
      params: { forceReload?: boolean, query: string }
    ): Promise<void> {
      console.log('OrdersStore - paginateOrders', params)
      context.commit('setOrdersSearchQuery', params.query)

      const page = params.query === context.state.ordersSearchQuery && !params.forceReload
        ? context.state.ordersPage + 1
        : 0

      try {
        const result = await ordersIndex.search(params.query, { hitsPerPage, page, cacheable: false })
        console.log('OrdersStore - paginateOrders - result', result)
        context.commit(
          'setOrders',
          {
            page,
            orders: params.forceReload === true ? result.hits : [...context.state.orders, ...result.hits]
          }
        )
      } catch (error) {
        console.error('OrdersStore - error searching inside the orders index', { context, params, error })
      }
    },
    getOrder: firestoreAction(async (context, id: string): Promise<void> => {
      console.debug('getOrder', { id })

      try {
        const result = (await ordersCollection.doc(id).get()).data() as IOrderModel
        console.log('OrdersStore - getOrder - result', result)

        context.commit('resetOrder', result)
      } catch (error) {
        console.error('OrdersStore - getWorkingSite - error getting a working site', { id, error })
        console.error(error)

        return undefined
      }
    }),
    getWorkingSites: firestoreAction(async (context, id: string): Promise<void> => {
      console.debug('getWorkingSites', { id })

      try {
        const result = (await ordersWorkingSitesCollection(id).get()).docs.map(d => d.data() as IWorkingSiteModel)
        console.log('OrdersStore - getWorkingSites - result', result)

        context.commit('resetWorkingSites', result)
      } catch (error) {
        console.error('OrdersStore - getWorkingSites - error getting a working site', { id, error })
        console.error(error)

        return undefined
      }
    }),

    // WorkingSite
    bindWorkingSiteRef: firestoreAction((context, payload) => {
      console.debug('bindWorkingSiteRef', { payload })
      // context contains all original properties like commit, state, etc
      // and adds `bindFirestoreRef` and `unbindFirestoreRef`
      // we return the promise returned by `bindFirestoreRef` that will
      // resolve once data is ready
      return context.bindFirestoreRef('workingSite', ordersWorkingSitesCollection(payload.orderId).doc(payload.id))
    }),
    unbindWorkingSiteRef: firestoreAction(({ unbindFirestoreRef }) => {
      console.debug('unbindWorkingSiteRef')
      unbindFirestoreRef('workingSite')
    }),
    createNewOrder: firestoreAction(async (context, payload: {
      name: string,
      customer: string,
      orderNumber: string
    }): Promise<IOrderModel | undefined> => {
      console.debug('createOrder')
      if (payload === undefined || payload.name === undefined) { return }

      const order: IOrderModel = {
        name: payload.name,
        id: UUID(),
        createdTs: (new Date()).valueOf() / 1000,
        updatedTs: (new Date()).valueOf() / 1000,
        isArchived: false,
        timings: []
      }

      if (payload.customer !== undefined && payload.customer.length > 0) {
        order.customer = payload.customer
      }
      if (payload.orderNumber !== undefined && payload.orderNumber.length > 0) {
        order.orderNumber = payload.orderNumber
      }

      const batch = db.batch()

      // Create the Working Site
      batch.set(ordersCollection.doc(order.id), order)

      await batch.commit()

      console.debug('createOrder - success')

      context.commit('addOrder', order)

      return order as any as IOrderModel
    }),
    updateOrder: firestoreAction(async (context, payload: {
      id: string,
      name: string,
      customer: string,
      isArchived: boolean,
      orderNumber: string
    }): Promise<boolean> => {
      console.debug('updateOrder', { payload })
      if (payload === undefined || payload.id === undefined) { return false }

      const order: { [key: string]: any } = {
        id: payload.id
      }

      if (payload.name !== undefined) { order.name = payload.name }
      if (payload.customer !== undefined) { order.customer = payload.customer }
      if (payload.isArchived !== undefined) { order.isArchived = payload.isArchived }
      if (payload.orderNumber !== undefined) { order.orderNumber = payload.orderNumber }

      order.updatedTs = (new Date()).valueOf() / 1000

      const batch = db.batch()

      // return the promise so we can await this action
      batch.update(
        ordersCollection.doc(order.id),
        order
      )

      await batch.commit()

      console.debug('updateOrder - success')

      return true
    }),
    createNewWorkingSite: firestoreAction(async (context, payload: {
      startTs: number,
      endTs?: number,
      name: string,
      address?: string,
      addressLatitude?: number,
      addressLongitude?: number,
      customer?: string,
      link?: string,
      isSpecialWorkingSite: boolean,
      userIds: string[],
      foremanIds: string[],
      orderId: string,
      timings: ITimingModel,
    }): Promise<IWorkingSiteModel | undefined> => {
      console.debug('createWorkingSite')
      if (payload === undefined || payload.name === undefined) { return }

      const workingSite: IWorkingSiteModel = {
        name: payload.name,
        startTs: payload.startTs,
        id: UUID(),
        createdTs: (new Date()).valueOf() / 1000,
        orderNumber: payload.orderId,
        isArchived: false,
        timings: [
          { closingTimeHours: 17, closingTimeMinutes: 0, openingTimeHours: 8, openingTimeMinutes: 0, weekDay: 1 },
          { closingTimeHours: 17, closingTimeMinutes: 0, openingTimeHours: 8, openingTimeMinutes: 0, weekDay: 2 },
          { closingTimeHours: 17, closingTimeMinutes: 0, openingTimeHours: 8, openingTimeMinutes: 0, weekDay: 3 },
          { closingTimeHours: 17, closingTimeMinutes: 0, openingTimeHours: 8, openingTimeMinutes: 0, weekDay: 4 },
          { closingTimeHours: 17, closingTimeMinutes: 0, openingTimeHours: 8, openingTimeMinutes: 0, weekDay: 5 }
        ],
        address: {
          address: payload.address,
          lat: payload.addressLatitude,
          lng: payload.addressLongitude
        }
      }

      // eslint-disable-next-line eqeqeq
      if (payload.endTs != undefined) {
        workingSite.endTs = payload.endTs
      }
      if (payload.customer !== undefined && payload.customer.length > 0) {
        workingSite.customer = payload.customer
      }

      const batch = db.batch()

      // Create the Working Site
      batch.set(ordersWorkingSitesCollection(payload.orderId).doc(workingSite.id), workingSite)

      await batch.commit()

      console.debug('createWorkingSite - success')

      return workingSite as any as IWorkingSiteModel
    }),
    updateTimings: firestoreAction(async (context, payload: {
      timings: ITimingModel,
      workingSiteId: string,
      orderId: string
    }): Promise<boolean> => {
      console.debug('OrdersStore - updateTimings', { payload })
      if (
        payload === undefined
        || payload.workingSiteId === undefined
        || payload.timings === undefined
      ) { return false }
      try {
        ordersWorkingSitesCollection(payload.orderId).doc(payload.workingSiteId).update({
          timings: payload.timings
        })
      } catch (error) {
        console.error('OrdersStore - updateTimings - error adding a working site timing', { context, payload, error })
        return false
      }

      console.debug('OrdersStore - updateTimings - success')

      return true
    }),
    updateWorkingSite: firestoreAction(async (context, payload: {
      id: string,
      startTs?: number,
      endTs?: number,
      name?: string,
      address?: string,
      isArchived?: boolean,
      addressLatitude?: number,
      addressLongitude?: number,
      customer?: string,
      link?: string,
      isSpecialWorkingSite?: boolean,
      userIds?: string[],
      removedUserIds?: string[],
      foremanIds?: string[],
      orderId: string
    }): Promise<boolean> => {
      console.debug('updateWorkingSite', { payload })
      if (payload === undefined || payload.id === undefined) { return false }

      const workingSite: { [key: string]: any } = {
        id: payload.id
      }

      if (payload.name !== undefined) { workingSite.name = payload.name }
      if (payload.address !== undefined) { workingSite['address.address'] = payload.address }
      if (payload.addressLatitude !== undefined && payload.addressLongitude !== undefined) {
        workingSite['address.lat'] = payload.addressLatitude
        workingSite['address.lng'] = payload.addressLongitude
      }
      if (payload.customer !== undefined) { workingSite.customer = payload.customer }
      if (payload.link !== undefined) { workingSite.link = payload.link }
      if (payload.orderId !== undefined) { workingSite.orderNumber = payload.orderId }
      if (payload.isSpecialWorkingSite !== undefined) { workingSite.isSpecialWorkingSite = payload.isSpecialWorkingSite }
      if (payload.startTs !== undefined) { workingSite.startTs = payload.startTs }
      if (payload.isArchived !== undefined) { workingSite.isArchived = payload.isArchived }
      if (payload.endTs !== undefined) { workingSite.endTs = payload.endTs }

      const batch = db.batch()

      // return the promise so we can await this action
      batch.update(
        ordersWorkingSitesCollection(payload.orderId).doc(workingSite.id),
        workingSite
      )

      await batch.commit()

      console.debug('updateWorkingSite - success')

      return true
    }),
    deleteWorking: firestoreAction(async (context, payload: {
      workingSiteId: string,
      orderId: string
    }): Promise<boolean> => {
      console.debug('deleteWorking', { payload })
      if (
        payload === undefined
        || payload.workingSiteId === undefined
        || payload.orderId === undefined
      ) { return false }

      await ordersWorkingSitesCollection(payload.orderId).doc(payload.workingSiteId).delete()

      console.debug('deleteWorking - success')

      return true
    }),

    async instantWorkingSites (
      context, ids: string[]
    ): Promise<IAlgoliaWorkingSiteModel[]> {
      console.log('OrdersStore - instantWorkingSites', { ids })

      try {
        const result = await workingSitesIndex.getObjects(ids, { cacheable: false })
        console.log('OrdersStore - instantWorkingSites - result', result)

        return result.results as IAlgoliaWorkingSiteModel[]
      } catch (error) {
        console.error('OrdersStore - instantWorkingSites - error getting the users from the index', { context, ids, error })
        console.error(error)

        return []
      }
    },

    async createQrCode (
      context: ActionContext<IOrdersStoreState, unknown>,
      orderId: string
    ): Promise<{jwt?: string, image?: string } | undefined> {
      console.debug('OrdersStore - createQRCode', { context })

      if (context.state.workingSite === undefined) { return undefined }

      const payload: IQRCodeWorkingSiteModel = {
        i: context.state.workingSite.id,
        n: context.state.workingSite.name,
        iat: (new Date()).valueOf() / 1000
      }

      // eslint-disable-next-line eqeqeq
      if (context.state.workingSite.address != undefined) {
        if ((context.state.workingSite.address as any).address !== undefined) {
          payload.a = (context.state.workingSite.address as any).address
        }
        if ((context.state.workingSite.address as any).lat !== undefined) {
          payload.lat = (context.state.workingSite.address as any).lat
        }
        if ((context.state.workingSite.address as any).lng !== undefined) {
          payload.lng = (context.state.workingSite.address as any).lng
        }
      }

      // eslint-disable-next-line eqeqeq
      if (orderId != undefined) {
        payload.oi = orderId
      }

      // unsubscribe can be called to stop listening for changes
      const result = await createQrCodeCallable(payload)

      console.debug('OrdersStore - createQRCode - result', { result })

      return result.data
    }
  }
}
