import { ActionContext, Module } from 'vuex'
import { IAuthenticationStoreState } from '../interfaces'
import { auth, usersCollection } from '@/utilities'
import firebase from 'firebase'
import { effectScope } from 'vue'
import { firestoreAction } from '@xquick-code/vuexfire'

// create and export a detached effect scope next to where you create your store
export const scope = effectScope(true)

export const AuthenticationStore: Module<IAuthenticationStoreState, unknown> = {
  namespaced: true,

  // setup the reactive todos property
  state: {
    authUser: undefined,
    isLoading: false,
    user: undefined,
    userListenerCanceller: undefined
  },

  getters: {
    getAuthUser (state): firebase.User | undefined | null {
      return state.authUser
    },
    isAuthenticated (state): boolean {
      return typeof state.authUser === 'object'
    }
  },

  mutations: {
    setAuthUser (state, payload) {
      console.debug('AuthenticationStore - setAuthUser', { state, payload })
      state.authUser = payload
    },
    setLoading (state, value: boolean) {
      console.debug('AuthStore - loading', { value })
      state.isLoading = value
    },
    setUserListenerCanceller (state, userListenerCanceller) {
      console.debug('AuthStore - setUserListenerCanceller', { userListenerCanceller })
      state.userListenerCanceller = userListenerCanceller
    }
  },

  actions: {
    bindAuthUser ({ commit, dispatch }): void {
      console.debug('AuthStore - bindAuthUser')

      auth.onAuthStateChanged((user: firebase.User | null) => {
        console.debug('AuthStore - bindAuthUser - received value', { user })
        if (user && typeof user === 'object') {
          commit('setAuthUser', user)
          dispatch('bindUserRef', user.uid)
        } else {
          commit('setAuthUser')
          dispatch('unbindUserRef')
        }
      })
    },

    // Authentication actions
    async signIn (
      context: ActionContext<IAuthenticationStoreState, unknown>,
      params: { email: string, password: string }
    ): Promise<boolean> {
      try {
        await auth
          .signInWithEmailAndPassword(params.email, params.password)
        // commit("setUser", null)
      } catch (error) {
        // commit("setError", error.message);
        console.error('AuthenticationStore - signIn error', { context, params, error })
        return false
      }

      return true
    },

    async signOut (
      context: ActionContext<IAuthenticationStoreState, unknown>
    ): Promise<void> {
      try {
        await auth.signOut()
        context.commit('setAuthUser', undefined)
      } catch (error) {
        // commit("setError", error.message);
        console.error('AuthenticationStore - signOut error', { error })
      }
    },

    // Vuex mutations
    bindUserDocument: firestoreAction(async ({ state, bindFirestoreRef }) => {
      console.debug('AuthenticationStore - bindUserDocument', { id: state.authUser ? state.authUser.uid : undefined })
      // eslint-disable-next-line eqeqeq
      if (state.authUser == undefined || state.authUser.uid == undefined) {
        return
      }

      await bindFirestoreRef('user', usersCollection.doc(state.authUser.uid))
    }),
    unbindUserDocument: firestoreAction(({ unbindFirestoreRef }) => {
      console.debug('AuthenticationStore - unbindUserDocument')
      unbindFirestoreRef('user')
    })
  }
}
