import { usersCollection, workingSitesCollectionGroup, workReportsCollectionGroup } from '@/utilities'
import { ActionContext, Module } from 'vuex'
import { vuexfireMutations } from '@xquick-code/vuexfire'
import { IWorkReportsStoreState } from '@/interfaces/work-reports-store-state.interface'
import { IOrderModel, IUserModel, IWorkingSiteModel, IWorkReport } from '@esse-group/shared'
import firebase from 'firebase'
import { ordersCollection } from '@/utilities/firebase-utility'

export const WorkReportsStore: Module<IWorkReportsStoreState, unknown> = {
  namespaced: true,

  // setup the reactive todos property
  state: {
    users: [] as IUserModel[],
    orders: [] as IOrderModel[],
    workingSites: [] as IWorkingSiteModel[],
    workReports: [] as IWorkReport[],
    workReportRawDocuments: [] as firebase.firestore.QueryDocumentSnapshot[]
  },

  getters: {
    workingSitedOrderGrouped (state: IWorkReportsStoreState) {
      // eslint-disable-next-line eqeqeq
      if (state.orders == undefined || state.orders.length < 1) {
        return []
      }

      return state.orders.filter(o => !o.isArchived).map((order) => ({
        ...order,
        workingSites: state.workingSites
          // eslint-disable-next-line eqeqeq
          ? state.workingSites.filter(ws => ws.orderNumber == order.id)
          : []
      }))
    }
  },

  mutations: {
    ...vuexfireMutations,
    setWorkReports (state, { workReports, rawData, forceReload }) {
      console.log('WorkReportsStore - setWorkReports', { workReports, rawData, forceReload })
      if (forceReload) {
        state.workReports = workReports
        state.workReportRawDocuments = rawData
      } else {
        state.workReports = [...state.workReports, ...workReports]
        state.workReportRawDocuments = [...state.workReportRawDocuments, ...rawData]
      }
    },
    setUsers (state, users) {
      console.log('WorkReportsStore - setUsers', { users })
      state.users = users
    },
    setOrders (state, orders) {
      console.log('WorkReportsStore - setOrders', { orders })
      state.orders = orders
    },
    setWorkingSites (state, workingSites) {
      console.log('WorkReportsStore - setWorkingSites', { workingSites })
      state.workingSites = workingSites
    },
    resetWorkReport (state) {
      state.workReports = []
      state.workReportRawDocuments = []
    }
  },

  actions: {
    async paginateWorkReports (
      context: ActionContext<IWorkReportsStoreState, unknown>,
      params: { workingSiteIds?: string[]; userIds?: string[]; limit?: number; forceReload?: boolean }
    ): Promise<void> {
      console.log('WorkReportsStore - paginateWorkReports', { params })

      let workReportRawDocuments: firebase.firestore.QueryDocumentSnapshot[] = []
      let workReportDocuments: IWorkReport[] = []

      try {
        let query = workReportsCollectionGroup

        if (params.userIds !== undefined) {
          query.where('userId', 'in', params.userIds)
        }
        if (params.workingSiteIds !== undefined) {
          query.where('workingSiteId', 'in', params.workingSiteIds)
        }
        if (params.limit !== undefined) {
          query = query.limit(params.limit)
        }

        query = query.orderBy('createdTs', 'desc')

        if (params.forceReload !== true && context.state.workReportRawDocuments.length > 0) {
          query = query.startAfter(context.state.workReportRawDocuments[
            (context.state.workReportRawDocuments as any).length - 1
          ])
        }

        workReportRawDocuments = (await query.get()).docs
        workReportDocuments = [...workReportRawDocuments].map(doc => doc.data()) as IWorkReport[]
      } catch (error) {
        console.error('WorkReportsStore - error fetching the workReports', { context, error })
      }

      context.commit('setWorkReports', { workReports: workReportDocuments, rawData: workReportRawDocuments, forceReload: params.forceReload })
    },

    async fetchUsers ({ commit }): Promise<boolean> {
      console.log('WorkReportsStore - fetchUsers')
      try {
        const result = await usersCollection.get()
        commit('setUsers', result.docs.map(d => d.data()))
      } catch (error) {
        console.error('WorkReportsStore - error searching inside the users index', { context, error })
        return false
      }

      return true
    },

    async fetchOrders ({ commit }): Promise<boolean> {
      console.log('WorkReportsStore - fetchOrders')
      try {
        const result = await ordersCollection.get()
        commit('setOrders', result.docs.map(d => d.data()))
      } catch (error) {
        console.error('WorkReportsStore - error searching inside the orders index', { context, error })
        return false
      }

      return true
    },

    async fetchWorkingSites ({ commit }): Promise<boolean> {
      console.log('WorkReportsStore - fetchWorkingSites')
      try {
        const result = await workingSitesCollectionGroup.get()
        commit('setWorkingSites', result.docs.map(d => d.data()))
      } catch (error) {
        console.error('WorkReportsStore - error fetching working sites', { context, error })
        return false
      }

      return true
    },

    async fetchData ({ dispatch }): Promise<boolean> {
      console.log('WorkReportsStore - fetchData')
      try {
        await Promise.all([
          dispatch('fetchWorkingSites'),
          dispatch('fetchOrders'),
          dispatch('fetchUsers')
        ])
      } catch (error) {
        console.error('WorkReportsStore - error fetching data', { context, error })
        return false
      }

      return true
    }
  }
}
