
import { defineComponent } from 'vue'
import ModalDialog from './ModalDialog.vue'
import PrimaryButton from '../buttons/PrimaryButton.vue'
import Loader from '../Loader.vue'
import { SuccessIcon } from '@/components/icons'
import { AlertType, EntityArchiveScope } from '@/enums'

export default defineComponent({
  name: 'ToggleEntityArchive',

  emits: ['close'],

  components: {
    ModalDialog, PrimaryButton, Loader, SuccessIcon
  },

  props: {
    isCurrentlyArchived: { type: Boolean, required: true },
    id: { type: String, required: true },
    name: { type: String, required: true },
    scope: { type: Number as () => EntityArchiveScope, required: true, default: EntityArchiveScope.workingSite }
  },

  data: function () {
    return {
      loading: false
    }
  },

  computed: {
    scopeName: function (): string {
      switch (this.scope) {
        case EntityArchiveScope.workingSite: return 'il cantiere'
        default: return ''
      }
    }
  },

  methods: {
    onArchive: async function () {
      this.loading = true

      switch (this.scope) {
        case EntityArchiveScope.workingSite: return this.toggleWorkingSite()
        default: this.loading = false
      }
    },
    toggleWorkingSite: async function () {
      this.loading = true

      try {
        const result = await this.$store.dispatch(
          'orders/updateWorkingSite',
          { id: this.id, isArchived: !this.isCurrentlyArchived }
        )

        this.loading = false
        if (!result) {
          await this.$store.dispatch('alerts/create', {
            message: this.isCurrentlyArchived ? 'archiviazione fallita' : 'ripristino fallito',
            type: AlertType.error
          })
          return
        }

        await this.$store.dispatch('alerts/create', {
          message: this.isCurrentlyArchived ? 'archiviazione riuscita' : 'ripristino riuscito',
          type: AlertType.success
        })
        // Add a delay to ensure that the Algolia Index is updated
        setTimeout(() => {
          // Then we reload the users
          this.$store.dispatch('orders/reloadWorkingSites')
        }, 4000)
        this.$emit('close')
      } catch (error) {
        console.error('error archiving the working site', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', {
          message: this.isCurrentlyArchived ? 'archiviazione fallita' : 'ripristino fallito',
          type: AlertType.error
        })
      }
    }
  }
})
