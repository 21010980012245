import config from '@/../algolia.json'
import { UserRole } from '@esse-group/shared'
import algoliasearch from 'algoliasearch'

// Get the algolia search instance
export const algoliaSearch = algoliasearch(config.projectId, config.clientKey)

// Define the indexes
export const usersIndex = algoliaSearch.initIndex(process.env.NODE_ENV === 'production' ? 'prod_users' : 'dev_users')
export const toolItemsIndex = algoliaSearch.initIndex(process.env.NODE_ENV === 'production' ? 'prod_toolitems' : 'dev_toolitems')
export const workingSitesIndex = algoliaSearch.initIndex(process.env.NODE_ENV === 'production' ? 'prod_workingsites' : 'dev_workingsites')
export const ordersIndex = algoliaSearch.initIndex(process.env.NODE_ENV === 'production' ? 'prod_orders' : 'dev_orders')
export const clockingInsIndex = algoliaSearch.initIndex(process.env.NODE_ENV === 'production' ? 'prod_clockingins' : 'dev_clockingins')

// Define some filters
export const usersEmployeesFilters = `role > ${UserRole.USER}`
