
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AccordionElement',

  props: {
    link: String,
    text: String,
    // eslint-disable-next-line vue/no-reserved-props
    key: String
  }
})
