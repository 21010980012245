
import { defineComponent } from 'vue'
import ModalDialog from './ModalDialog.vue'
import PrimaryButton from '../buttons/PrimaryButton.vue'
import Loader from '../Loader.vue'
import { DeleteIcon } from '@/components/icons'
import { AlertType } from '@/enums'
import { createNamespacedHelpers } from 'vuex'
import { IUserModel } from '@esse-group/shared'
import { usersEmployeesFilters } from '@/utilities'
const usersHelper = createNamespacedHelpers('users')

export default defineComponent({
  name: 'DeleteUser',

  emits: ['close'],

  components: {
    ModalDialog, PrimaryButton, Loader, DeleteIcon
  },

  props: {
    userId: { type: String, required: true }
  },

  data: function () {
    return {
      loading: false
    }
  },

  methods: {
    ...usersHelper.mapActions(['updateUser', 'reloadUsers']),
    onDelete: async function () {
      this.loading = true

      try {
        const result = await this.updateUser({
          id: this.userId,
          isEnabled: false,
          isArchived: true,
          updatedTs: (new Date().valueOf()) / 1000
        } as Partial<IUserModel>)

        this.loading = false
        if (!result) {
          await this.$store.dispatch('alerts/create', {
            message: 'eliminazione utente fallita',
            type: AlertType.error
          })
          return
        }

        await this.$store.dispatch('alerts/create', {
          message: 'eliminazione utente riuscita',
          type: AlertType.success
        })

        // Add a delay to ensure that the Algolia Index is updated
        setTimeout(() => {
          // Then we reload the users
          this.$store.dispatch('users/reloadUsers', { forceReload: true, filters: usersEmployeesFilters })
          console.log('users reloaded')
        }, 5000)

        this.$emit('close')
      } catch (error) {
        console.error('error deleting the user', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', {
          message: 'eliminazione utente fallita',
          type: AlertType.error
        })
      }
    }
  }
})
