
import { defineComponent } from 'vue'
import ModalDialog from './ModalDialog.vue'
import PrimaryButton from '../buttons/PrimaryButton.vue'
import Loader from '../Loader.vue'
import { AlertType } from '@/enums'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { IWorkingSiteModel } from '@esse-group/shared'
import Toggle from '@/components/Toggle.vue'
import Checkbox from '@/components/Checkbox.vue'
import { LeftArrow, SuccessIcon } from '@/components/icons'
import { dateValidator } from '@/validators'
import { DatePicker } from 'v-calendar'
import moment from 'moment'

export default defineComponent({
  name: 'AddEditWorkingSiteModalDialog',

  emits: ['close'],

  components: {
    ModalDialog, PrimaryButton, Loader, LeftArrow, SuccessIcon, Toggle, Checkbox, DatePicker
  },

  props: {
    modelValue: {
      type: Object as () => IWorkingSiteModel,
      required: false,
      default: undefined
    }
  },

  setup () {
    return { v$: useVuelidate() }
  },

  mounted: function (): void {
    if (this.modelValue !== undefined) {
      this.step1.id = this.modelValue.id
      this.step1.name = this.modelValue.name ?? ''
      // eslint-disable-next-line eqeqeq
      if (this.modelValue.address != undefined) {
        this.step1.address = this.modelValue.address.address ?? ''
        // eslint-disable-next-line eqeqeq
        if (this.modelValue.address.lat != undefined) {
          this.step1.lat = this.modelValue.address.lat ?? ''
        }
        // eslint-disable-next-line eqeqeq
        if (this.modelValue.address.lng != undefined) {
          this.step1.lng = this.modelValue.address.lng ?? ''
        }
      }
      if (this.modelValue.startTs) {
        this.step1.startDate = moment.unix(this.modelValue.startTs).toDate()
      }
      if (this.modelValue.endTs) {
        this.step1.endDate = moment.unix(this.modelValue.endTs).toDate()
      }
    }
  },

  data () {
    return {
      step: 0,
      step1: {
        id: '',
        name: '',
        address: undefined as string | undefined,
        lat: undefined as number | undefined,
        lng: undefined as number | undefined,
        startDate: (new Date()) as Date | null,
        endDate: null as Date | null,
        customer: undefined,
        link: undefined
      },
      loading: false
    }
  },

  computed: {
    // eslint-disable-next-line eqeqeq
    isEditing: function (): boolean { return this.modelValue != undefined },
    title: function (): string {
      if (this.step > 1) { return '' }
      return this.isEditing
        ? 'Stai aggiornando questo blocco'
        : 'Stai aggiungendo un nuovo blocco'
    },
    subtitle: function (): string {
      if (this.step > 1) { return '' }
      if (this.isEditing) { return 'Modifica le seguenti informazioni' }
      switch (this.step) {
        case 0: return 'Aggiungi le seguenti informazioni'
        default: return ''
      }
    },
    wsaPlaceholderIsAddress: function (): boolean {
      // eslint-disable-next-line eqeqeq
      return this.step1.address != undefined && this.step1.address != ''
    },
    workingSiteAddressPlaceholder: function (): string {
      // eslint-disable-next-line eqeqeq
      return this.step1.address != undefined && this.step1.address != '' ? this.step1.address : 'Inserisci la località'
    },
    orderId: function () {
      return this.$route.params.orderId
    }
  },

  validations () {
    return {
      step1: {
        name: { required },
        address: { required },
        lat: { required },
        lng: { required },
        startDate: { dateValidator, required },
        endDate: { dateValidator }
      }
    }
  },

  methods: {
    onStep1: function () {
      console.log('onStep1')
      this.step = 0
    },
    onClose: function () {
      this.$emit('close')
    },

    // Step 1
    onGmapAutocompleteAddress: function (evt: any) {
      console.log('onGmapAutocompleteAddress', { evt })
      // eslint-disable-next-line eqeqeq
      if (evt != undefined) {
        this.step1.address = evt.formatted_address !== undefined ? evt.formatted_address : ''
        if (evt.geometry !== undefined && evt.geometry.location !== undefined) {
          this.step1.lat = evt.geometry.location.lat()
          this.step1.lng = evt.geometry.location.lng()
        } else {
          this.step1.lat = undefined
          this.step1.lng = undefined
        }
      } else {
        this.step1.address = ''
        this.step1.lat = undefined
        this.step1.lng = undefined
      }
    },

    create: async function () {
      this.loading = true

      // Validate the fields
      this.v$.step1.$touch()
      if (this.v$.step1.$error) {
        this.loading = false
        return
      }

      try {
        if (this.orderId == null) {
          await this.$store.dispatch('alerts/create', {
            message: 'creazione blocco fallita, provare a ricaricare la pagina e riprovare',
            type: AlertType.error
          })
          return
        }
        const endTs = moment(this.step1.endDate as any)
        const result = await this.$store.dispatch(
          'orders/createNewWorkingSite',
          {
            name: this.step1.name,
            address: this.step1.address,
            startTs: moment(this.step1.startDate as any).unix(),
            endTs: endTs.isValid() ? endTs.unix() : null,
            customer: this.step1.customer,
            addressLatitude: this.step1.lat,
            addressLongitude: this.step1.lng,
            orderId: this.orderId
          }
        )

        this.loading = false
        if (result === undefined) {
          await this.$store.dispatch('alerts/create', {
            message: 'creazione blocco fallita',
            type: AlertType.error
          })
          return
        }

        await this.$store.dispatch('alerts/create', {
          message: 'creazione blocco riuscita',
          type: AlertType.success
        })
        // Add a delay to ensure that the Algolia Index is updated
        // Then we reload the users
        this.$store.dispatch('orders/getWorkingSites', this.orderId)

        // this.$emit('close')
        this.onClose()
      } catch (error) {
        console.error('error creating the working site', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', {
          message: 'creazione blocco fallita',
          type: AlertType.error
        })
      }
    },

    // Update
    update: async function () {
      this.loading = true

      // Validate the fields
      this.v$.$touch()
      if (this.v$.$error) {
        this.loading = false
        return
      }

      try {
        const endTs = moment(this.step1.endDate as any)
        const result = await this.$store.dispatch(
          'orders/updateWorkingSite',
          {
            id: this.step1.id,
            name: this.step1.name,
            address: this.step1.address,
            startTs: moment(this.step1.startDate as any).unix(),
            endTs: endTs.isValid() ? endTs.unix() : null,
            customer: this.step1.customer,
            link: this.step1.link,
            addressLatitude: this.step1.lat,
            addressLongitude: this.step1.lng,
            orderId: this.orderId
          }
        )

        this.loading = false
        if (!result) {
          await this.$store.dispatch('alerts/create', {
            message: 'aggiornamento blocco fallito',
            type: AlertType.error
          })
          return
        }

        await this.$store.dispatch('alerts/create', {
          message: 'aggiornamento blocco riuscito',
          type: AlertType.success
        })
        // Add a delay to ensure that the Algolia Index is updated
        setTimeout(() => {
          // Then we reload the users
          this.$store.dispatch('orders/reloadWorkingSites')
        }, 4000)
      } catch (error) {
        console.error('error updating the working site', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', {
          message: 'aggiornamento blocco fallito',
          type: AlertType.error
        })
      }
      this.onClose()
    }
  }
})
