
import { defineComponent } from 'vue'
import { Loader } from '@/components'
import { createNamespacedHelpers } from 'vuex'
import { AlertType } from '@/enums'
const { mapActions } = createNamespacedHelpers('orders')

export default defineComponent({
  name: 'WorkingSiteTagCard',

  components: {
    Loader
  },

  data () {
    return {
      deleteRelatedDocument: false,
      loading: false,
      isEditing: false
    }
  },

  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },

  computed: {
    id: function (): string {
      return (this.$route as any).params.workingSiteId
    }
  },

  methods: {
    ...mapActions(['deleteWorkingSiteTag', 'updateWorkingSiteTag']),
    onTagDelete: async function () {
      console.log('onTagDelete')
      this.loading = true

      try {
        const result = await this.deleteWorkingSiteTag({ workingSiteId: this.id, tagId: this.modelValue.id })

        this.loading = false
        if (!result) {
          await this.$store.dispatch('alerts/create', { message: 'eliminazione tag fallita', type: AlertType.error })
          return
        }

        await this.$store.dispatch('alerts/create', { message: 'eliminazione tag riuscita', type: AlertType.success })
      } catch (error) {
        console.error('error creating the document', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', { message: 'eliminazione tag fallita', type: AlertType.error })
      }
    },
    onTagEdit: function () {
      console.log('onTagEdit')
      this.isEditing = true
    },
    onSave: async function () {
      console.log('onSave')
      this.loading = true

      try {
        const result = await this.updateWorkingSiteTag({ workingSiteId: this.id, tagId: this.modelValue.id, name: this.modelValue.name })

        this.loading = false
        if (!result) {
          await this.$store.dispatch('alerts/create', { message: 'aggiornamento tag fallito', type: AlertType.error })
          return
        }

        await this.$store.dispatch('alerts/create', { message: 'aggiornamento tag riuscito', type: AlertType.success })

        this.isEditing = false
      } catch (error) {
        console.error('error creating the document', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', { message: 'aggiornamento tag fallito', type: AlertType.error })
      }
    }
  }
})
