
import { defineComponent } from 'vue'
import ModalDialog from './ModalDialog.vue'
import PrimaryButton from '../buttons/PrimaryButton.vue'
import Loader from '../Loader.vue'
import { AlertType } from '@/enums'
import { ForemanToWorkerIcon, WorkerToForemanIcon } from '@/components/icons'
import Checkbox from '@/components/Checkbox.vue'

export default defineComponent({
  name: 'ChangeUserRoleDialog',

  emits: ['close'],

  components: {
    ModalDialog, PrimaryButton, Loader, Checkbox, ForemanToWorkerIcon, WorkerToForemanIcon
  },

  props: {
    workingSiteId: { type: String, required: true },
    userId: { type: String, required: true },
    userName: { type: String, required: true },
    isForeman: { type: Boolean, default: false, required: false }
  },

  data () {
    return {
      loading: false
    }
  },

  methods: {

    changeRole: async function (): Promise<void> {
      this.loading = true

      try {
        const result = await this.$store.dispatch(
          'orders/updateWorkingSite',
          {
            id: this.workingSiteId,
            userIds: [this.userId],
            foremanIds: this.isForeman ? [] : [this.userId]
          }
        )

        this.loading = false
        if (result === undefined) {
          await this.$store.dispatch('alerts/create', {
            message: 'aggiornamento ruolo fallita',
            type: AlertType.error
          })
          return
        }

        await this.$store.dispatch('alerts/create', {
          message: 'aggiornamento ruolo riuscita',
          type: AlertType.success
        })
        this.$emit('close')
      } catch (error) {
        console.error('error creating the working site', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', {
          message: 'aggiornamento ruolo fallita',
          type: AlertType.error
        })
      }
    }
  }
})
