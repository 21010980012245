
import { defineComponent } from 'vue'
import ModalDialog from './ModalDialog.vue'
import PrimaryButton from '../buttons/PrimaryButton.vue'
import Loader from '../Loader.vue'
import { DatePicker } from 'v-calendar'
import { AlertType, ClockingInScope } from '@/enums'
import { IClockingInModel, ITimingModel, IUserModel, IWorkingSiteModel } from '@esse-group/shared'
import { IOrdersStoreState } from '@/interfaces/orders-store-state.interface'
import { IUsersStoreState } from '@/interfaces/users-store-state.interface'
import useVuelidate from '@vuelidate/core'
import { v4 as UUID } from 'uuid'
import { required, requiredIf } from '@vuelidate/validators'
import { createNamespacedHelpers } from 'vuex'
import { humanizedClockingInTiming } from '@/utilities'
import VueMultiselect from 'vue-multiselect'
const { mapGetters } = createNamespacedHelpers('auth')
const { mapState } = createNamespacedHelpers('orders')

export default defineComponent({
  name: 'AddEditClockingInDialog',

  emits: ['close', 'created'],

  components: {
    ModalDialog, PrimaryButton, Loader, DatePicker, VueMultiselect
  },

  setup () {
    return { v$: useVuelidate() }
  },

  validations () {
    return {
      time: { required },
      clockingInType: { required },
      userId: { requiredIf: requiredIf(this.scope === ClockingInScope.workingSite) },
      selectedWorkingSiteId: { requiredIf: requiredIf(this.scope === ClockingInScope.user) }
    }
  },

  props: {
    id: { type: String, required: true },
    scope: {
      type: Number as () => ClockingInScope,
      required: true
    },
    user: { type: Object as () => IUserModel | undefined, required: false },
    workingSite: { type: Object as () => IWorkingSiteModel | undefined, required: false }
  },

  data () {
    return {
      loading: false,
      clockingInType: 'in' as 'in' | 'out',
      timing: undefined as number | undefined,
      timings: [] as ITimingModel[],

      selectedOrderId: undefined as string | undefined,
      selectedWorkingSiteId: undefined as string | undefined,

      users: [] as IUserModel[],

      userId: undefined as string | undefined,
      time: new Date() as Date | undefined,

      ClockingInScope,
      humanizedClockingInTiming
    }
  },

  computed: {
    ...mapGetters({
      authUser: 'getAuthUser'
    }),
    ...mapState<IUsersStoreState>({
      users: (state: any) => state.users
    }),
    ...mapState<IOrdersStoreState>({
      orders: (state: IOrdersStoreState) => state.orders,
      workingSites: (state: IOrdersStoreState) => state.workingSites
    })
  },

  /**  Bind Vuexfire on client-side: */
  async beforeMount () {
    try {
      console.log(this.$store.state.orders)
      // this.searchQuery = this.$store.state.orders.ordersSearchQuery
      await this.$store.dispatch('orders/reloadOrders')
    } catch (e) {
      console.error('error dispatching the firestore mutation bindOrdersRef')
      console.error(e)
    }
  },

  async mounted () {
    console.debug('AddEditClockingInDialog', { scope: this.scope, id: this.id })
    // eslint-disable-next-line eqeqeq
    if (this.scope == ClockingInScope.user && this.id != undefined) {
      this.userId = this.id as string
      this.loadWorkingSites()
    }
    // eslint-disable-next-line eqeqeq
    if (this.scope == ClockingInScope.workingSite) {
      // eslint-disable-next-line eqeqeq
      if (this.$route.params.orderId != undefined && this.$route.params.workingSiteId != undefined) {
        this.selectedOrderId = this.$route.params.orderId as string
        this.selectedWorkingSiteId = this.$route.params.workingSiteId as string
      }

      this.loadUsers()
    }
  },

  methods: {
    loadUsers: async function () {
      console.debug('loadUsers', { workingSiteId: this.selectedWorkingSiteId })
      if (this.selectedWorkingSiteId === undefined) { return }
      this.users = await this.$store.dispatch('users/instantSearchUsers', {
        hitsPerPage: 999,
        page: 0
      })
      console.debug('loadUsers', { users: this.users })
    },
    displayNameAndSurname (user: IUserModel) {
      return `${user.surname} ${user.name}`
    },
    onChange: function () {
      this.loadWorkingSites()
    },
    onCreate: async function () {
      console.log('on confirm', this.userId)
      this.v$.$touch()

      console.log('error', { error: this.v$.error, user: this.userId })
      // eslint-disable-next-line eqeqeq
      if (this.v$.error || this.userId == undefined) {
        this.loading = false
        return
      }

      this.loading = true

      const doc: IClockingInModel = {
        id: UUID(),
        position: {},
        userId: this.userId,
        createdByUserId: this.authUser !== undefined ? this.authUser.uid : undefined,
        workingSiteId: this.selectedWorkingSiteId,
        // eslint-disable-next-line eqeqeq
        isClockingOut: this.clockingInType == 'out',
        orderId: this.selectedOrderId,
        createdTs: Math.trunc((this.time !== undefined ? this.time : new Date()).valueOf() / 1000)
      }

      try {
        const result = await this.$store.dispatch('users/createClockingIn', doc)

        if (!result) {
          await this.$store.dispatch('alerts/create', { message: 'creazione timbratura fallita', type: AlertType.error })
          return
        }

        await this.$store.dispatch('alerts/create', { message: 'creazione timbratura riuscita', type: AlertType.success })

        this.loading = false
        this.$emit('created')
        this.$emit('close')
      } catch (error) {
        console.error('error creating the clocking in', error)
        this.$emit('close')
        await this.$store.dispatch('alerts/create', { message: 'creazione timbratura fallita', type: AlertType.error })
      }
    },
    loadWorkingSites: async function () {
      if (this.selectedOrderId === undefined) { return }
      console.debug('loadWorkingSites', { selectedOrderId: this.selectedOrderId })
      await this.$store.dispatch('orders/getWorkingSites', this.selectedOrderId)
    }
  }
})
