
import { defineComponent } from 'vue'
import ModalDialog from './ModalDialog.vue'
import PrimaryButton from '../buttons/PrimaryButton.vue'
import Loader from '../Loader.vue'
import Toggle from '../Toggle.vue'
import { AlertType, EditUserModalDialogMode } from '@/enums'
import { IUserModel, UserRole, UserAgency } from '@esse-group/shared'
import useVuelidate from '@vuelidate/core'
import { email, required, minLength, requiredIf } from '@vuelidate/validators'
import { usersEmployeesFilters } from '@/utilities'

export default defineComponent({
  name: 'EditUserModalDialog',

  emits: ['close'],

  components: {
    ModalDialog, PrimaryButton, Loader, Toggle
  },

  props: {
    mode: {
      type: Number as () => EditUserModalDialogMode,
      required: false,
      default: EditUserModalDialogMode.editEmployee
    },
    modelValue: {
      type: Object,
      required: false,
      default: undefined
    }
  },

  setup () {
    return { v$: useVuelidate() }
  },

  validations () {
    return {
      user: {
        name: { required },
        surname: { required },
        email: { required, email },
        password: {
          required: requiredIf(this.mode === EditUserModalDialogMode.addUser || this.mode === EditUserModalDialogMode.addEmployee || this.mode === EditUserModalDialogMode.editEmployeeCredentials),
          minLength: minLength(6)
        }
      }
    }
  },

  data () {
    return {
      loading: false,
      isSeePassword: false,
      passwordFieldType: 'password',
      agencies: [
        { name: 'Esse Group', value: UserAgency.ESSE_GROUP },
        { name: 'Asten', value: UserAgency.ASTEN },
        { name: 'Generazione Vincente', value: UserAgency.GENERAZIONE_VINCENTE },
        { name: 'Arkigest', value: UserAgency.ARKIGEST }
      ],
      user: {
        id: '',
        name: undefined,
        surname: undefined,
        phone: undefined,
        taxCode: undefined,
        agency: undefined,
        email: undefined,
        isEnabled: false,
        role: UserRole.GUEST,
        password: undefined
      } as IUserModel & { password: string | undefined },
      UserRole,
      EditUserModalDialogMode,
      availableUserRoles: Object.entries(UserRole)
        .filter(kv => isNaN(Number(kv[1])) && (kv[1] as any) !== 'USER')
        .map((kv: any) => {
          switch (kv[1]) {
            case UserRole[UserRole.GUEST]: return [kv[0], 'Ospite']
            case UserRole[UserRole.USER]: return [kv[0], 'Utente generico']
            case UserRole[UserRole.WORKER]: return [kv[0], 'Dipendente']
            case UserRole[UserRole.ADMIN]: return [kv[0], 'Amministratore']
            case UserRole[UserRole.SUPER_ADMIN]: return [kv[0], 'Super amministratore']
            default: return kv
          }
        })
    }
  },

  mounted: function () {
    console.log('EditUserModalDialog - mounted')
    // eslint-disable-next-line eqeqeq
    if (this.modelValue != undefined) {
      (this as any).user = { ...this.modelValue }
      console.log('role', { modelValue: this.modelValue })
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    title: function (): string {
      switch (this.mode) {
        case EditUserModalDialogMode.editEmployee: return 'Stai modificando l\'anagrafica dipendente'
        case EditUserModalDialogMode.editEmployeeCredentials: return 'Stai modificando le credenziali dipendente'
        case EditUserModalDialogMode.editUser: return 'Stai modificando l\'anagrafica dell\'utente'
        case EditUserModalDialogMode.addEmployee: return 'Stai aggiungendo un dipendente'
        case EditUserModalDialogMode.addUser: return 'Stai aggiungendo un utente'
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    subtitle: function (): string {
      switch (this.mode) {
        case EditUserModalDialogMode.editEmployee:
        case EditUserModalDialogMode.editEmployeeCredentials:
        case EditUserModalDialogMode.editUser:
          return 'Modifica le seguenti informazioni'
        case EditUserModalDialogMode.addEmployee:
        case EditUserModalDialogMode.addUser:
          return 'Inserisci le seguenti informazioni'
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    actionTitle: function (): string {
      switch (this.mode) {
        case EditUserModalDialogMode.editEmployee:
        case EditUserModalDialogMode.editEmployeeCredentials:
        case EditUserModalDialogMode.editUser:
          return 'Aggiorna'
        case EditUserModalDialogMode.addEmployee:
        case EditUserModalDialogMode.addUser:
          return 'Aggiungi'
      }
    }
  },

  methods: {
    mainAction: function () {
      switch (this.mode) {
        case EditUserModalDialogMode.editEmployee:
        case EditUserModalDialogMode.editEmployeeCredentials:
        case EditUserModalDialogMode.editUser:
          return this.update()
        case EditUserModalDialogMode.addEmployee:
        case EditUserModalDialogMode.addUser:
          return this.create()
      }
    },
    seeHidePassword: function () {
      this.isSeePassword = !this.isSeePassword
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    create: async function () {
      console.debug('EditUserModalDialog - create')
      this.loading = true

      // Validate the fields
      this.v$.$touch()
      if (this.v$.$error) {
        this.loading = false
        return
      }

      if (this.mode === EditUserModalDialogMode.addEmployee) {
        this.user.role = UserRole.WORKER
      }

      try {
        const result = await this.$store.dispatch(
          'users/createNewWorker',
          {
            name: this.user.name,
            surname: this.user.surname,
            phone: this.user.phone,
            taxCode: this.user.taxCode,
            email: this.user.email,
            agency: this.user.agency,
            isEnabled: this.user.isEnabled,
            role: this.user.role,
            breakTimeMinutes: 60,
            password: this.user.password,
            workingTime: [
              { minutes: 480, weekDay: 0 },
              { minutes: 480, weekDay: 1 },
              { minutes: 480, weekDay: 2 },
              { minutes: 480, weekDay: 3 },
              { minutes: 480, weekDay: 4 },
              { minutes: 480, weekDay: 5 },
              { minutes: 480, weekDay: 6 }
            ]
          }
        )
        console.debug('EditUserModalDialog - create - done', { result })
        // eslint-disable-next-line eqeqeq
        if (!result) {
          this.loading = false
          this.$emit('close')
          await this.$store.dispatch('alerts/create', { message: 'creazione utente fallita', type: AlertType.error })
          return
        }
        this.loading = false
        this.$emit('close')
        await this.$store.dispatch('alerts/create', { message: 'creazione utente riuscita', type: AlertType.success })
        // Add a delay to ensure that the Algolia Index is updated
        setTimeout(() => {
          // Then we reload the users
          this.$store.dispatch('users/reloadUsers', { forceReload: true, filters: usersEmployeesFilters })
        }, 6000)
      } catch (error) {
        this.loading = false
        console.error('error creating the user', error)
        await this.$store.dispatch('alerts/create', { message: 'creazione utente fallita', type: AlertType.error })
      }
    },
    update: async function () {
      console.log('EditUserModalDialog - update', { old: this.modelValue, updated: this.user })
      this.loading = true

      // Validate the fields
      this.v$.$touch()
      if (this.v$.$error) {
        return
      }

      const changedValues: Partial<IUserModel> = { id: this.user.id }
      // eslint-disable-next-line eqeqeq
      if (this.user.name != undefined && this.modelValue != undefined && (this.modelValue.name == undefined || (this.user.name.trim() !== this.modelValue.name.trim()))) {
        changedValues.name = this.user.name
      }
      // eslint-disable-next-line eqeqeq
      if (this.user.surname != undefined && this.modelValue != undefined && (this.modelValue.surname == undefined || (this.user.surname.trim() !== this.modelValue.surname.trim()))) {
        changedValues.surname = this.user.surname
      }
      // eslint-disable-next-line eqeqeq
      // eslint-disable-next-line eqeqeq
      if (this.user.phone != undefined && this.modelValue != undefined) {
        changedValues.phone = this.user.phone
      }
      // eslint-disable-next-line eqeqeq
      if (this.user.email != undefined && this.modelValue != undefined && (this.modelValue.email == undefined || (this.user.email.trim() !== this.modelValue.email.trim()))) {
        changedValues.email = this.user.email
      }
      // eslint-disable-next-line eqeqeq
      if (this.user.taxCode != undefined && this.modelValue != undefined && (this.modelValue.taxCode == undefined || (this.user.taxCode.trim() !== this.modelValue.taxCode.trim()))) {
        changedValues.taxCode = this.user.taxCode
      }
      // eslint-disable-next-line eqeqeq
      if (this.user.agency != undefined && this.modelValue != undefined && (this.modelValue.agency == undefined || (this.user.agency.trim() !== this.modelValue.agency.trim()))) {
        changedValues.agency = this.user.agency
      }
      // eslint-disable-next-line eqeqeq
      if (this.user.isEnabled != undefined && this.modelValue != undefined && (this.modelValue.isEnabled == undefined || (this.user.isEnabled !== this.modelValue.isEnabled))) {
        changedValues.isEnabled = this.user.isEnabled
      }
      // eslint-disable-next-line eqeqeq
      if (this.user.role != undefined && this.modelValue != undefined && (this.modelValue.role == undefined || (this.user.role !== this.modelValue.role))) {
        changedValues.role = Number(this.user.role)
      }
      // eslint-disable-next-line eqeqeq
      if (this.user.password != undefined) {
        (changedValues as any).password = this.user.password
      }

      if (Object.keys(changedValues).length < 2) {
        await this.$store.dispatch('alerts/create', { message: 'nessun campo da aggiornare', type: AlertType.error })
        return
      }

      try {
        const result = await this.$store.dispatch('users/updateUser', changedValues)
        // eslint-disable-next-line eqeqeq
        if (result != true) {
          await this.$store.dispatch('alerts/create', { message: 'aggiornamento utente fallito', type: AlertType.error })
          return
        }

        await this.$store.dispatch('alerts/create', { message: 'aggiornamento utente riuscito', type: AlertType.success })
        if (this.mode === EditUserModalDialogMode.editEmployee || this.mode === EditUserModalDialogMode.editEmployeeCredentials) {
          setTimeout(async () => {
            // Then we reload the users
            await this.$store.dispatch('users/reloadUsers', { forceReload: true, filters: usersEmployeesFilters })
          }, 6000)
        }
        if (this.mode === EditUserModalDialogMode.editUser) {
          setTimeout(async () => {
            // Then we reload the users
            await this.$store.dispatch('users/reloadUsers', { forceReload: true })
            this.loading = false
          }, 6000)
        }
        this.$emit('close')
      } catch (error) {
        console.error('error creating the user', error)
        await this.$store.dispatch('alerts/create', { message: 'aggiornamento utente fallito', type: AlertType.error })
      }
    }
  }
})
